<template>
    <div>
        <HelpTerm>Image quality too low?</HelpTerm>
        You need an image at least
        <strong>{{ helpcontext.minWidth }}</strong>
        px wide X
        <strong>{{ helpcontext.minHeight }}</strong>
        px high.
        <br />
        Here's how you can check image quality before uploading:
        <ul>
            <li>
                Locate &amp;
                <strong>Right-click</strong>
                on the image
            </li>
            <li>
                Select the
                <strong>Properties</strong>
                option near the bottom.
            </li>
            <li>
                In the Properties window, select the
                <strong>Details</strong>
                tab.
            </li>

            <li>
                Verify the
                <strong>
                    Image Width is at least {{ helpcontext.minWidth }} and Height is at least
                    {{ helpcontext.minHeight }}
                </strong>
                pixels.
            </li>
        </ul>

        <HelpPleaseNote>
            If the image doesn't meet
            <strong>both</strong>
            the minimum height and width, the image cannot be used.
        </HelpPleaseNote>
    </div>
</template>
<script>
export default {
    props: {
        helpcontext: { type: Object, default: null },
    },
};
</script>
<style lang="scss" scoped>
ul {
    list-style-type: decimal;
    list-style-position: inside;
    margin: 10px 0 20px 20px;

    li {
        margin: 15px 0;
    }
}
.images {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    span {
        flex: 1 0 39%;
        max-width: 39%;
        position: relative;

        h4 {
            position: absolute;
            right: 5px;
            top: 5px;
        }
    }
    span:first-of-type {
        flex: 1 0 21%;
        max-width: 21%;
    }
}
.image-number {
    background: #ff8001;
    box-shadow: #000 0 2px 3px 0;
    color: #fff;
    padding: 5px 11px;
}
</style>
