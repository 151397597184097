<template>
    <div
        :class="{ 'float-right': float === 'right', 'float-left': float === 'left' }"
        class="pleasenote foldtl"
    >
        <strong>
            <slot name="label">Please Note:</slot>
        </strong>
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: {
        float: { type: String, default: null },
    },
};
</script>
<style lang="scss" scoped>
.pleasenote {
    margin: 0 20px 15px 20px;
    padding: 12px;
    background-color: $light-blue;
    font-size: 13px;
}
.float-right {
    margin: 0 0 15px 15px;
    float: right;
    width: 35%;
}
.float-left {
    margin: 0 15px 15px 0;
    float: left;
    width: 35%;
}
.foldtl {
    position: relative;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.foldtl:before {
    content: '';
    position: absolute;
    top: 0%;
    left: 0%;
    width: 0px;
    height: 0px;
    border-bottom: 10px solid $light-blue;
    border-left: 10px solid #fff; /*Set to background color, not transparent!*/
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
</style>
