<template>
    <div class="spinholder" :style="{ width: width, height: height }">
        <div class="spinit">
            <div :style="{ width: width, height: height, 'border-thickness': thickness }"></div>
        </div>
    </div>
</template>
<script>
export default {
    props: { size: { type: Number, default: 16 }, thick: { type: Number, default: 3 } },
    data() {
        return {
            width: this.$props.size + 'px',
            height: this.$props.size + 'px',
            thickness: (this.$props.thick || 3) + 'px',
        };
    },
};
</script>
<style lang="scss" scoped>
@include keyframes(spinit) {
    0% {
        @include transform(rotate(0deg));
    }
    100% {
        @include transform(rotate(360deg));
    }
}

.spinholder {
    display: inline-block;
}

.spinit {
    position: relative;
}
.spinit div {
    position: absolute;
    border-radius: 50%;
    border: 3px solid #000;
    border-color: $orange transparent $orange transparent;
    @include animation('spinit 1.1s linear infinite');
}
</style>
