<template>
    <ChangeModal ref="modal" help="HelpSocial">
        <span slot="title">Social Badges &amp; Feeds</span>
        <span slot="description">
            Activate your social media badges and/or social feeds. Missing any social media?
            <a
                :href="`${publicPath}docs/Social_Media_Guidelines_-_Desjardins_10-16-2019.pdf`"
                target="_blank"
                aria-describedby="new-window-warning"
                rel="noopener noreferrer"
            >
                Learn More
            </a>
        </span>

        <div slot="editor">
            <div class="feed-wrapper">
                <div v-if="!hasSocial" class="toggles">
                    <h2>Social Media Not Available</h2>
                    <div>
                        You currently do not have any Social Media added to
                        <HelpSAP />
                        and activated on the
                        <HelpSAP />
                        . Please use the
                        <strong>Help</strong>
                        button below to read more about troubleshooting this.
                    </div>
                </div>
                <div v-if="hasSocial" class="toggles">
                    <h2>Social Media Badges</h2>
                    <ul>
                        <li v-for="feed in social.feeds" :key="feed">
                            <switches
                                v-if="social[feed]"
                                v-model="social[feed].visible"
                                :color="social[feed].visible ? 'blue' : 'unchecked'"
                                class="item switch"
                                theme="custom"
                            />
                            <span v-else class="item switch"></span>
                            <span class="item">{{ feed }}</span>
                            <span class="item socialbuttons">
                                <span
                                    :class="[
                                        'c1-icon-stack',
                                        feed,
                                        { 'is-disabled': !social[feed] || !social[feed].visible },
                                    ]"
                                    aria-hidden="true"
                                >
                                    <svgicon
                                        :icon="feed.toLowerCase()"
                                        :original="true"
                                        height="27"
                                        width="27"
                                    />
                                </span>
                            </span>
                            <span v-if="!social[feed]" class="item no-account is-disabled">
                                <small>No {{ feed }} account set up</small>
                            </span>
                            <span v-if="social[feed]" class="item no-account is-disabled"></span>
                        </li>
                    </ul>
                </div>
                <div class="feeds">
                    <div v-if="hasSocial" class="social-feed">
                        <h2>Active Social Feed</h2>
                        <label
                            v-for="feed in activeSocialFeeds"
                            :key="feed"
                            :class="{ 'is-disabled': !social[feed] && feed !== 'None' }"
                        >
                            <input
                                v-model="activeSocialFeed"
                                :disabled="!social[feed] && feed !== 'None'"
                                :value="feed"
                                type="radio"
                            />
                            {{ feed }}
                            <small v-if="!social[feed] && feed !== 'None'">
                                - No {{ feed }} account set up
                            </small>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div slot="footer">
            <BaseBtn
                v-if="canSave"
                :disabled="saving"
                :text="saving ? 'Saving...' : 'Save Changes'"
                @click.native="save"
            />
        </div>
    </ChangeModal>
</template>

<script>
import Switches from 'vue-switches';
import _ from 'lodash';

export default {
    label: 'Social',
    components: {
        Switches,
        // SocialButtons,
    },
    data() {
        return {
            DesjardinsSocialUrl: '[@desjardins: SOCIAL_MEDIA_COMPLIANCE_GUIDELINES_URL]',
            activeSocialFeeds: ['Facebook', 'Twitter', 'None'],
            activeSocialFeed: this.$store.getters.social.activeSocialFeed,
            saving: false,
            social: _.cloneDeep(this.$store.getters.social),
            publicPath: process.env.BASE_URL,
        };
    },
    computed: {
        canSave() {
            if (_.isEqual(this.social, this.$store.getters.social)) {
                return false;
            } else {
                return true;
            }
        },
        hasSocial() {
            return Object.keys(this.$store.getters.office.social).length > 0;
        },
    },
    watch: {
        activeSocialFeed(feed) {
            if (_.get(this.social, 'Facebook', false)) {
                this.$set(this.social.Facebook, 'activeFeed', false);
            }
            if (_.get(this.social, 'Twitter', false)) {
                this.$set(this.social.Twitter, 'activeFeed', false);
            }
            if (feed !== 'None') {
                this.$set(this.social[feed], 'activeFeed', true);
            }
        },
    },
    mounted() {
        this.social.badges.forEach((b) => (this.social[b.socialMediaType].visible = true));
    },
    methods: {
        save() {
            if (this.canSave) {
                this.saving = true;
                this.social.feeds.forEach((feed) => {
                    let coPromises = [];
                    if (this.socialChanged(feed, 'activeFeed')) {
                        coPromises.push(
                            this.$store.dispatch('SEND_CONTENT_OVERRIDE', {
                                value: this.social[feed].activeFeed,
                                status: 'approved',
                                key: `office.social.${feed}.activeFeed`,
                            }),
                        );
                    }

                    if (this.socialChanged(feed, 'visible')) {
                        coPromises.push(
                            this.$store.dispatch('SEND_CONTENT_OVERRIDE', {
                                value: this.social[feed].visible,
                                status: 'approved',
                                key: `office.social.${feed}.visible`,
                            }),
                        );
                    }

                    Promise.all(coPromises)
                        .then(this.hideEditor)
                        .catch(() => {
                            return this.$refs.modal.displayError(
                                new Error('problem creating the content override'),
                            );
                        })
                        .finally(() => {
                            this.saving = false;
                        });
                });
            }
        },
        socialChanged(name, key) {
            return !_.isEqual(
                _.get(this.social, `${name}.${key}`, null),
                _.get(this.$store.getters.social, `${name}.${key}`, null),
            );
        },
    },
};
</script>

<style lang="scss" scoped>
h2 {
    margin-bottom: 10px;
}
ol {
    padding: 0 20px;
}
.feed-wrapper {
    display: flex;
    justify-content: space-between;

    .socialbuttons {
        align-self: center;
        font-size: 18px;
        @include media('>=lg') {
            font-size: 15px;
        }
        @include media('<=sm') {
            font-size: 10px;
        }
        @include media('<=xs') {
            font-size: 10px;
        }
        list-style: none;
        display: flex;
        li {
            display: list-item;
        }
        li + li {
            margin-left: 0px;
            padding-left: 0px;
        }
        a:hover {
            opacity: 0.6;
        }
        .facebook {
            color: #3b5998;
        }
        .twitter {
            color: #0084b4;
        }
        .linkedin {
            color: #4875b4;
        }
        .yelp {
            color: #d32323;
        }
        .forelogo {
            font-size: 1.2em;
            color: #fff;
        }
    }
    .toggles {
        width: 60%;

        ul {
            list-style: none;
            li {
                align-items: center;
                display: flex;
                justify-content: flex-start;
                margin: 10px 0;

                em {
                    font-size: 12px;
                }

                .item {
                    width: 20%;
                    &.switch {
                        width: 10%;
                    }
                    &.socialbuttons {
                        width: 10%;
                    }
                    &.no-account {
                        width: 50%;
                    }
                }
            }
        }
    }
    .feeds {
        display: flex;
        flex-direction: column;
        margin: auto;

        .social-feed {
            display: flex;
            flex-direction: column;
            label {
                align-items: center;
                display: flex;
                font-size: 16px;
                margin-top: 5px;
            }
            input[type='radio'] {
                appearance: none;
                width: 20px;
                height: 20px;
                margin: 0 5px 0 0;
                cursor: pointer;
                border-radius: 50%;
                border: 2px solid $blue;
                transition: background 0.4s, border-color 0.4s, box-shadow 0.4s;

                &:checked {
                    background: $blue;
                    box-shadow: inset 0 0 0 2px #fff;
                }
            }
        }
    }
}
.is-disabled {
    cursor: not-allowed;
    opacity: 0.5;

    i {
        opacity: 0.5;
    }
    input {
        cursor: not-allowed !important;
    }
    small {
        font-style: italic;
    }
}
</style>
