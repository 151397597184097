<!--
Ok... you're probably thinking "why the heck is this the way it is", well:
     1. theres a bunch of date math involved here. Including but not limited to merging date ranges together, comparing date ranges, timezones, and cleanly selecting dates. Wsing date-fns and es6 Dates makes that easier
     2. he want/chose to store the office hours in the timezone of the office, aka the offices "wall clock". This minimizes the usage of timezones. One specific thing this fixes is the tech support people making changes on behalf of an agent in different timezone then the agent.
     3. because of 1 and 2, I store the office hours as a simplified wall clock object {hours: int, minutes: int}, but interact with it in this component (and WorkTime.vue) using the real Date objects.
     4. dates/times/timezones are hard https://www.youtube.com/watch?v=-5wpm-gesOY

if you think you can and should refactor this, remember
   - all active content overides will need to be updated
   - because of the "The office is open now" feature, we need to be able to tell if the browsers current time is inside the ranges of the current days office hours. that means converting 1 timezone to another (refer to reason 4 above). My bet is that your refactor wont end up being much cleaner then it is now. make the bug fix, and leave while you still can
   - WorkTime.vue merges days with equivelent ranges together. so have fun with that
   - the TimePicker component expects es6 Dates

- Alec, Jan 23 2019 4:46 PM CDT
-->
<template>
    <div class="office-hours-editor">
        <ChangeModal ref="modal" help="HelpOfficeHours">
            <span slot="title">Office Hours</span>
            <span slot="description">
                Indicate to your customers at what times your office is open for business.
            </span>
            <span slot="editor">
                <div class="days-list">
                    <div class="day">
                        <span class="name"></span>
                        <div class="open-switch"></div>
                        <div class="timezone-select">
                            <span class="timezone label">Timezone</span>
                            <span class="timezone value">{{ mappedTimezone }}</span>
                        </div>
                    </div>

                    <div v-for="(day, i) in hoursWithoutAfterHours" :key="day.name" class="day">
                        <span class="name">{{ day.name }}</span>
                        <div v-if="day.openForBusiness !== undefined" class="open-switch">
                            <Switches
                                v-model="day.openForBusiness"
                                class="switch"
                                theme="custom"
                                :color="day.openForBusiness ? 'blue' : 'unchecked'"
                                @click.native="updateLookedAtDays(i)"
                            ></Switches>
                            <div class="open-label">
                                {{ day.openForBusiness ? 'Open' : 'Closed' }}
                            </div>
                        </div>

                        <div v-if="day.openForBusiness === true" class="hours">
                            <div
                                v-for="(range, j) in day.times"
                                :key="j"
                                class="range"
                                :class="{ 'no-pad-top': j === 0 }"
                            >
                                <TimePicker
                                    :value="range.start"
                                    :error="overlapsWithAnotherRange(j, day.times)"
                                    @input="(value) => updateDayTime(i, j, value, 'start')"
                                ></TimePicker>
                                &nbsp;to&nbsp;
                                <TimePicker
                                    :value="range.end"
                                    :error="overlapsWithAnotherRange(j, day.times)"
                                    @input="(value) => updateDayTime(i, j, value, 'end')"
                                ></TimePicker>
                                &nbsp;
                                <button
                                    v-if="day.times.length > 1"
                                    class="hours-button remove"
                                    @click="removeHoursFromDay(i, j)"
                                >
                                    Remove
                                </button>
                                <button
                                    v-if="lastTimeKey(i, j)"
                                    class="hours-button add"
                                    @click="addHoursToDay(i)"
                                >
                                    Add Hours
                                </button>
                            </div>
                        </div>
                        <div v-if="hasOverlapingRanges(day.times)" class="overlap-error">
                            {{ errorMessage }}
                        </div>
                    </div>
                </div>
                <div class="after-hours">
                    <div class="day">
                        <span class="name">{{ afterHours.name }}</span>
                        <div class="open-switch">
                            <switches
                                v-model="afterHours.byAppointment"
                                class="switch byAppointment"
                                theme="custom"
                                :color="afterHours.byAppointment ? 'blue' : 'unchecked'"
                            ></switches>
                            <span class="open-label">
                                {{ afterHours.byAppointment ? 'Enabled' : 'Disabled' }}
                            </span>
                        </div>
                    </div>
                </div>
            </span>
            <div slot="footer" class="footer">
                <BaseBtn
                    v-if="canSave()"
                    v-tooltip="$_error(saveDisabled(), errorMessage)"
                    class="tooltip-target b3"
                    :disabled="saving || saveDisabled()"
                    :text="saving ? 'Saving...' : 'Save Changes'"
                    @click.native="save"
                />
            </div>
        </ChangeModal>
    </div>
</template>

<script>
import Switches from 'vue-switches';
import TimePicker from './../widgets/TimePicker.vue';
import { format, parse, areIntervalsOverlapping, isEqual, addHours } from 'date-fns';
import {
    cloneDeep,
    isArray,
    pullAt,
    last,
    set,
    filter,
    find,
    every,
    isEqual as _isEqual,
} from 'lodash';
import { mapGetters } from 'vuex';
import { Tooltips } from './../mixins/Tooltips.js';
import { complicate, simplify, mapTimezone } from './../../../utils/hours.js';

function defaultDay(name) {
    return {
        name,
        openForBusiness: true,
        times: [
            {
                start: parse('09:00', 'HH:mm', new Date()),
                end: parse('17:00', 'HH:mm', new Date()),
            },
        ],
    };
}

export default {
    name: 'OfficeHours',
    components: {
        Switches,
        TimePicker,
    },
    mixins: [Tooltips],
    data() {
        return {
            hours: [
                defaultDay('Monday'),
                defaultDay('Tuesday'),
                defaultDay('Wednesday'),
                defaultDay('Thursday'),
                defaultDay('Friday'),
                defaultDay('Saturday'),
                defaultDay('Sunday'),
                {
                    name: 'After Hours by Appointment',
                    byAppointment: false,
                },
            ],
            lookedAtDays: [false, false, false, false, false, false],
            saving: false,
            errorMessage:
                'You must fix overlapping office hours (highlighted in red) before saving.',
        };
    },
    computed: {
        ...mapGetters(['office']),
        mappedTimezone() {
            return mapTimezone(this.office.physicalAddress.timezone);
        },
        hoursWithoutAfterHours() {
            return filter(this.hours, (hour) => !hour.name.includes('After Hours'));
        },
        afterHours() {
            return find(this.hours, (hour) => hour.name.includes('After Hours'));
        },
        condensedHours() {
            return simplify(
                this.hours.map((day) => {
                    if (isArray(day.times)) {
                        day.times = this.mergeTimes(day.times);
                    }
                    return day;
                }),
            );
        },
    },
    mounted() {
        if (isArray(this.office.structuredHours)) {
            this.hours = complicate(this.office.structuredHours);
            this.lookedAtDays = [true, true, true, true, true, true];
        }
    },

    methods: {
        compareTime(a, b) {
            // date-fns has comparison functions, but actual dates mess with things,
            // so straight military time comparison is easier
            return Number(format(a, 'HHmm')) - Number(format(b, 'HHmm'));
        },
        updateDayTime(i, j, value, change) {
            let newRange = { ...this.hours[i].times[j] };
            newRange[change] = value;

            // end should be after start
            if (this.compareTime(newRange.start, newRange.end) >= 0) {
                this.$refs.modal.displayNotification(
                    'Start time must be before end time.',
                    'warning',
                );
            } else {
                this.hours[i].times.splice(j, 1, newRange);
            }
        },
        removeHoursFromDay(i, j) {
            this.hours[i].times.splice(j, 1);
        },
        addHoursToDay(i) {
            let previousTime = last(this.hours[i].times);
            let start = addHours(previousTime.end, 1);
            let end = addHours(start, 1);
            this.hours[i].times.push({ start, end });
        },
        lastTimeKey(i, j) {
            return this.hours[i].times.length - 1 === j;
        },
        mergeTimes(times) {
            let sortedRanges = times.sort((a, b) => this.compareTime(a.start, b.start));
            let stack = pullAt(sortedRanges, 0);

            sortedRanges.forEach((range) => {
                let top = last(stack);

                if (
                    areIntervalsOverlapping(top, range) ||
                    isEqual(top.start, range.end) ||
                    isEqual(top.end, range.start)
                ) {
                    if (this.compareTime(range.end, top.end) > 0) {
                        stack[stack.length - 1] = set(top, 'end', range.end);
                    }
                } else {
                    stack.push(range);
                }
            });

            return stack;
        },
        overlapsWithAnotherRange(checkIndex, times) {
            let currentRange = times[checkIndex];
            for (let i = 0; i < times.length; i++) {
                if (checkIndex > i && areIntervalsOverlapping(currentRange, times[i])) {
                    return true;
                }
            }
            return false;
        },
        hasOverlapingRanges(times) {
            for (let i = 0; i < times.length; i++) {
                if (this.overlapsWithAnotherRange(i, times)) {
                    return true;
                }
            }
            return false;
        },
        updateLookedAtDays(i) {
            if (!this.lookedAtDays[i]) {
                let defaultTimes = [
                    {
                        start: parse('09:00', 'HH:mm', new Date()),
                        end: parse('17:00', 'HH:mm', new Date()),
                    },
                ];

                for (let j = 0; j < i; j++) {
                    if (this.hours[j].openForBusiness) {
                        defaultTimes = cloneDeep(this.hours[j].times);
                    }
                }

                this.hours[i].times = defaultTimes;
                this.lookedAtDays[i] = true;
            }
        },
        fromatedCondensedTimes(i) {
            let times = cloneDeep(this.hours[i].times);
            return this.mergeTimes(times).map(
                (r) => `${format(r.start, 'hh:mm a')} to ${format(r.end, 'hh:mm a')}`,
            );
        },
        applyCondensedHour(i) {
            let day = cloneDeep(this.hours[i]);
            day.times = this.mergeTimes(day.times);
            this.$set(this.hours, i, day);
        },
        save() {
            this.saving = true;

            return this.$store
                .dispatch('SEND_CONTENT_OVERRIDE', {
                    value: this.condensedHours,
                    status: 'approved',
                    key: 'office.structuredHours',
                })
                .then(() => {
                    this.saving = false;
                })
                .then(this.hideEditor)
                .catch(() => {
                    // should do something with actual error
                    this.saving = false;
                    return this.$refs.modal.displayError(
                        new Error('problem creating the content override'),
                    );
                });
        },
        canSave() {
            if (every(this.hours, (day) => !day.openForBusiness)) {
                return false;
            }

            if (_isEqual(this.condensedHours, this.office.structuredHours)) {
                return false;
            }

            return true;
        },
        saveDisabled() {
            for (let i = 0; i < this.hours.length - 1; i++) {
                if (this.hasOverlapingRanges(this.hours[i].times)) {
                    return true;
                }
            }
            return false;
        },
    },
};
</script>

<style lang="scss">
.office-hours-editor {
    .timezone-select {
        margin-top: 7px;

        .timezone {
            padding: 0.5em 0.75em;
            border: 1px solid transparent;
            border-color: #dbdbdb;
            color: #363636;

            &.label {
                font-weight: 700;
                font-size: 14px;
                border-right: none;
                background-color: #f5f5f5;
                font-weight: bold;
            }
        }
    }

    .footer {
        display: flex;
        flex-direction: row;
    }

    .body {
        max-height: 50vh;
        min-height: 60vh;
        padding-right: 15px;
        overflow-y: scroll;
    }

    .after-hours {
        display: flex;
        flex-direction: column;
        width: 100%;

        .day {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: stretch;

            &:nth-child(1) {
                border-top: 1px solid $gray;
            }

            @include media('<md') {
                .name,
                open-switch {
                    flex-basis: auto;
                }
            }
        }
    }

    .name,
    .open-switch {
        flex-basis: 17%;
        align-self: flex-start;
        padding-top: 10px;

        .open-label {
            margin-left: 10px;
        }
    }

    .name {
        flex-basis: 25%;
    }

    .open-switch {
        display: flex;
    }

    .message {
        align-self: center;
        flex-basis: auto;
    }

    .days-list {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .day {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;
        border-top: 1px solid $gray;
        padding: 5px 5px;
        min-height: 47px;

        &:nth-child(1) {
            border-top: none;
        }

        .hours-button {
            padding: 5px;
            margin: 0px 3px;

            color: white;
            border: none;
            font-weight: 700;

            &.add {
                background-color: $blue;

                &:hover {
                    background-color: lighten($blue, 10%);
                }
            }

            &.remove {
                background-color: $red;

                &:hover {
                    background-color: lighten($red, 5%);
                }
            }
        }

        .hours {
            padding-top: 4px;
            flex-basis: auto;
            display: flex;
            flex-direction: column;

            .range {
                padding: 3px 0px;

                &.no-pad-top {
                    padding-top: 0px;
                }
            }
        }

        @include media('<md') {
            .timezone-select {
                margin-bottom: 20px;
            }

            .name,
            open-switch {
                flex-basis: auto;
            }

            .open-switch {
                align-self: center;
                flex-basis: 60%;
                padding: 10px;
            }

            .hours {
                flex-basis: 100%;
                align-self: center;
            }

            .hours-button {
                margin: 5px;
            }
        }
    }

    .overlap-error {
        text-align: center;
        margin-top: 5px;
        width: 100%;
        color: $red;

        ul {
            border: 1px solid $red;
            margin: 7px 0px;
            padding: 5px;
            list-style: none;
        }

        .hours-button {
            margin: 0px;
        }
    }
}
</style>
