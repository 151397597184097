<template>
    <HelpBaseBlock :editnow="true" editaction="sap" source="sap">
        <p>
            The {{ helpcontext.type }} Profile Photo is the perfect way to introduce yourself to
            your customers and help them "put a face to the name" before they get in touch. Where
            other insurers treat them like a number, you're a real person who treats them like a
            <em>human</em>
            . Make sure to keep this photo updated and professional, as it's one of the first
            impressions a customer will get of your office.
        </p>

        <div slot="troubleshooting">
            <HelpImageSize :helpcontext="helpcontext"></HelpImageSize>
        </div>
    </HelpBaseBlock>
</template>

<script>
export default {
    props: {
        helpcontext: { type: Object, default: null },
    },
};
</script>
