<template>
    <div v-if="editingAllowed" id="editor-menu" :style="{ height: menuHeight }">
        <div class="container">
            <div class="row align-items-center">
                <div class="col">
                    <div class="editor-avatar">
                        <div><span>EM</span></div>
                    </div>
                    <h4>Enhanced Microsite Editor</h4>
                    <p class="identity">
                        {{ $store.getters.editor.user.email_address }}
                    </p>
                </div>
                <div class="col">
                    <div class="logout">
                        <button class="bar-button" @click="logout()">
                            <!-- <EMIcon :icon="'external-link'" />  -->
                            Exit Editor
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-env browser */

export default {
    data() {
        return {
            menuHeight: '75px',
        };
    },
    mounted() {
        if (this.editingAllowed) {
            this.offsetBody(this.menuHeight);
        }
    },
    methods: {
        offsetBody(amount) {
            const body = document.getElementsByTagName('body')[0];
            body.style.paddingTop = amount;
        },
        logout() {
            this.offsetBody(0);
            this.$store.commit('LOGOUT');
        },
    },
};
</script>

<style lang="scss" scoped>
#editor-menu {
    h4 {
        margin: 4px;
        white-space: nowrap;
        font-family: $base-font;
        @include media('<=sm') {
            font-size: 18px;
        }
    }
    .identity {
        white-space: nowrap;
        @include media('<=sm') {
            font-size: 14px;
        }
    }
    .editor-avatar {
        float: left;
        width: 45px;
        margin-right: 10px;
        @include media('<=sm') {
            margin-left: 10px;
            width: 35px;
        }
        div {
            height: 0;
            width: 100%;
            text-align: center;
            padding-bottom: 100%;
            border-radius: 50%;
            margin-bottom: 10px;
            overflow: hidden;
            background-color: $light-blue;
            vertical-align: middle;
            img {
                width: 100%;
            }
            span {
                // font-family: $base-font;
                font-size: 25px;
                font-weight: 800;
                line-height: 1.8;
                color: $blue;
                @include media('<=sm') {
                    font-size: 20px;
                }
            }
        }
    }
    box-shadow: 0 5px 15px 6px rgba(0, 0, 0, 0.66);
    color: $light-blue;
    z-index: 1000;
    position: fixed;
    top: 0;
    margin: auto;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $blue;
    padding: 5px 0;
}
.logout {
    display: flex;
    justify-content: flex-end;
}
.bar-button {
    white-space: nowrap;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: $light-blue;
    padding: 5px 8px;
    text-decoration: none;
    align-items: center;
    // border-radius: 4px;
    border: 1px solid $light-blue;
    background-color: transparent;
    text-align: center;

    &:hover {
        color: #fff;
        background-color: $light-orange;
    }
    i {
        margin-right: 3px;
        @include media('<=sm') {
            display: none;
        }
    }

    @include media('<=md') {
        font-size: 10px;
    }
}
</style>
