<template>
    <a class="mybluelink" :href="`mailto:support@mydadomain.com?subject=EM Question: ${subject}`">
        {{ linkText }}
    </a>
</template>

<script>
export default {
    props: {
        subject: { type: String, default: '' },
        linkText: { type: String, default: 'support@mydadomain.com' },
    },
};
</script>

<style scoped>
.mybluelink {
    color: blue;
}
</style>
