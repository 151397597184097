import { render, staticRenderFns } from "./HelpComingSoon.vue?vue&type=template&id=8e47531e&scoped=true"
var script = {}
import style0 from "./HelpComingSoon.vue?vue&type=style&index=0&id=8e47531e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e47531e",
  null
  
)

export default component.exports