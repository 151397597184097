<template>
    <ImageUploader
        :aid="`${$store.getters.agent.aid}`"
        :guidelines="guidelines"
        :circle-mask="true"
        :aspect-ratio="100 / 126"
        help="HelpProfileImage"
        image-type="profile"
        keypath="agent.image.profile"
        title="Agent Profile Photo"
    />
</template>

<script>
import ImageUploader from './../widgets/ImageUploader.vue';

export default {
    components: { ImageUploader },
    data() {
        const name = this.$store.getters.agent.names.NickName;
        return {
            guidelines: [
                `The photo is <strong>at least 200px wide X 200px high</strong>.`,
                `The photo is of the upper-body, <strong>not</strong> full length.`,
                `This is a reasonably current photo of ${name}.`,
                `The <strong>entire face</strong> is visible (no sunglasses).`,
                `This photo is of <strong>${name} alone</strong>.`,
                `This is a photo, not an illustration.`,
                `This photo presents a <strong>professional image</strong>.`,
                `${name} is wearing business or business casual attire.`,
                `The photo <strong>completely fills</strong> the square area.`,
            ],
        };
    },
};
</script>
