<template>
    <transition name="change-modal">
        <div id="change-modal-wrapper" @mousedown="mouseDown" @mouseup="mouseUp">
            <div
                id="change-modal-container"
                class="change-modal-container"
                @mousedown="mouseDown"
                @mouseup="mouseUp"
            >
                <div class="change-modal-header">
                    <div class="title">
                        <span>
                            <slot name="title-prefix">
                                {{ showingHelp ? 'Help for' : 'Editing' }}
                            </slot>
                        </span>
                        <span class="title-slot">
                            <slot name="title">title</slot>
                        </span>
                    </div>
                    <BaseBtn
                        v-if="showCancel"
                        :mobileshowicon="true"
                        class="closer"
                        :icon="{
                            color: 'black',
                            height: '20',
                            name: 'close',
                            width: '20',
                        }"
                        @click.native="hideEditor()"
                    />
                </div>
                <div v-if="!showingHelp" class="description">
                    <slot name="description"></slot>
                </div>

                <transition-group name="change-slide-fade" tag="div" class="notification-list">
                    <div
                        v-for="(notification, index) in notificationsToDisplay"
                        :key="notification.message + index"
                        class="notification"
                        :class="[notification.type]"
                    >
                        <span class="message">{{ notification.message }}</span>
                        <BaseBtn
                            text="dismiss"
                            class="dismiss"
                            @click.native="closeNotification(index)"
                        />
                        <!-- <button @click="closeNotification(index)">close</button> -->
                    </div>
                </transition-group>

                <div class="change-modal-body">
                    <div v-if="help && showingHelp" class="helpbody">
                        <!-- <slot name="help"></slot> -->
                        <component
                            :is="$root.$options.components[help]"
                            :helpcontext="helpcontext"
                        ></component>
                    </div>
                    <slot v-else name="editor"></slot>
                </div>

                <div class="change-modal-footer is-clearfix">
                    <div class="is-pulled-left mode-buttons">
                        <HelpBtn v-if="help && !showingHelp" />
                        <BaseBtn
                            v-if="$slots.editor && showingHelp"
                            class="modeBtn edit"
                            text="Edit"
                            :icon="{
                                color: 'white',
                                height: '15',
                                name: 'pencil',
                                width: '15',
                            }"
                            @click.native="toggleHelp()"
                        />
                    </div>
                    <div class="is-pulled-right action-buttons">
                        <slot v-if="!showingHelp" name="footer"></slot>
                        <BaseBtn
                            v-if="showCancel"
                            text="Cancel"
                            class="cancel"
                            @click.native="hideEditor()"
                        />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { get, take, isEqual, isNil } from 'lodash';

export default {
    props: {
        help: { type: String, default: null },
        helpcontext: { type: Object, default: null },
    },
    data() {
        return {
            notifications: [],
            mouseDownID: null,
        };
    },
    computed: {
        showCancel() {
            return get(this.$store.getters.editor, 'showCancel', true);
        },
        showingHelp() {
            return this.$store.getters.editor.showHelp;
        },
        notificationsToDisplay() {
            return take(this.notifications, 5);
        },
    },
    methods: {
        mouseDown(event) {
            this.mouseDownID = event.target.id;
        },
        mouseUp(event) {
            const maskID = 'change-modal-wrapper';
            const mouseUpID = event.target.id;
            if (isEqual(maskID, this.mouseDownID) && isEqual(maskID, mouseUpID)) {
                this.hideEditor();
            }
        },
        sessionExpired() {
            this.$store.commit('UPDATE_EDITOR', {
                showHelp: false,
                editing: 'SessionExpired',
                showCancel: false,
            });
        },
        displayError(error) {
            this.displayNotification(error.message, 'error');
        },
        displayNotification(message, type, timeout = 4000) {
            let newNotif = { message, type };
            if (isNil(newNotif.type)) {
                newNotif.type = 'info';
            }
            let index = this.notifications.push(newNotif) - 1;

            if (timeout > 0) {
                setTimeout(() => {
                    this.closeNotification(index);
                }, timeout);
            }
        },
        closeNotification(index) {
            this.notifications.splice(index, 1); // ie support down to 5.5 :)
        },
    },
};
</script>

<style lang="scss">
$RADIUS: 5px;

#change-modal-wrapper {
    position: fixed;
    overflow-y: scroll;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 35px 0;
    @include media('<=sm') {
        padding: 0;
    }

    .basic-button {
        &.cancel {
            color: $blue;
            background-color: transparent;
            &:hover {
                color: lighten($blue, 10%);
            }
        }
        &.closer {
            font-size: 35px;
            margin: 0;
            padding: 0;
            @include media('<=sm') {
                font-size: 20px;
            }
            background-color: transparent;
            color: $dark;
            &:hover {
                color: $gray;
            }
        }
    }
    .basic-button + .basic-button {
        margin-left: 10px;
    }
}

.change-modal-container {
    width: 85%;
    max-width: 1200px;

    @include media('<=sm') {
        width: 100%;
        border-radius: 0;
    }

    margin: auto;
    background-color: #fff;
    border-radius: $RADIUS;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.66);
    transition: all 0.3s ease;
    font-family: $base-font;

    .notification-list {
        width: 85%;
        max-width: 1200px;
        position: absolute;
        z-index: 2;

        @include media('<=sm') {
            width: 100%;
        }

        .notification {
            transition: all 0.5s;
            margin: 0px 0px 2px 0px;
            padding: 5px;
            display: flex;
            justify-content: space-between;
            color: white;

            .message {
                margin-top: 3px;
                vertical-align: middle;
                font-size: 16px;
                margin-left: 16px;
            }

            .dismiss {
                padding: 4px 10px;
                background-color: white;
            }

            &.error {
                background-color: $red;

                .dismiss {
                    color: $red;
                }
            }

            &.warning {
                background-color: $orange;

                .dismiss {
                    color: $orange;
                }
            }

            &.success {
                background-color: $green;

                .dismiss {
                    color: $green;
                }
            }

            &.info {
                background-color: $blue;

                .dismiss {
                    color: $blue;
                }
            }
        }
    }
}

.change-modal-header {
    border-bottom: 2px solid $light-blue;
    background-color: $light-blue;
    padding: 10px 20px 0px 20px;
    border-radius: $RADIUS $RADIUS 0px 0px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    color: $dark;

    .title {
        font-family: $base-font;
        padding-top: 3px;
        font-size: 25px;
        margin-top: 0;
        color: $dark;

        .title-slot {
            color: $darker;
            font-weight: 800;
        }

        @include media('<=sm') {
            font-size: 20px;
        }
    }
}

.change-modal-body {
    font-family: $base-font;
    /* flex: 0 1 auto; */
    margin-top: 6px;
    padding: 6px 20px;
    width: 100%;
    // overflow-y: auto;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $base-font;
    }
    ul {
        margin-bottom: 0px;
    }

    p {
        font-family: $base-font;
        font-size: 18px;
        line-height: 1.3;
        margin-bottom: 15px;
    }
    .helpbody {
        p {
            font-size: 16px;
            margin-left: 15px;
        }
        ol {
            font-size: 15px;
            list-style-type: circle;
            li {
                margin-left: 15px;
            }
        }
    }
}
.mode-buttons {
    padding-top: 5px;
    .modeBtn {
        // float : left;
        margin-left: 20px;
        padding: 3px 10px;
        font-weight: normal;
        &.help {
            color: #fff;
            background-color: $blue;
            &:hover {
                background-color: lighten($green, 10%);
            }
        }
        &.edit {
            color: #fff;
            background-color: $orange;
            &:hover {
                background-color: $light-orange;
            }
        }
    }
}
.action-buttons {
    display: flex;
    padding-bottom: 5px;
}

.change-modal-footer {
    background-color: $gray;
    border-top: 2px solid $gray;
    border-radius: 0px 0px $RADIUS $RADIUS;
    display: flex;
    justify-content: space-between;
    padding: 8px 20px 2px;
}

.description {
    padding: 10px 25px;
    background-color: $light-blue;
    color: $darker;
    font-size: 14px;
}

.change-modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.change-modal-enter {
    opacity: 0;
}

.change-modal-leave-active {
    opacity: 0;
}

.change-modal-enter .change-modal-container,
.change-modal-leave-active .change-modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

/*
 * transitions for notification
 */
.change-slide-fade-leave-active {
    /* position: absolute; */
}
.change-slide-fade-enter,
.change-slide-fade-leave-to {
    transform: translateY(-10px);
    opacity: 0;
}
.change-slide-fade-move {
    transition: transform 0.5s;
}
</style>
