<template>
    <ChangeModal help="HelpLandmark">
        <span slot="title">Landmark</span>
        <div slot="editor">
            <h3>Landmark</h3>
            <p>
                By default, we use your 'long' landmark text from
                <strong>SAP</strong>
                . If you would like us to use your short landmark or both your long and short
                landmarks, please select those from the option below. Note: This will only affect
                your Enhanced Microsite, your Default Microsite will still show the 'long' landmark
                from SAP.
            </p>
            <div class="landmark-editor-body">
                <p v-if="landmark.long && !landmark.short">
                    SAP only shows you have long landmark text right now so that is what will be
                    selected.
                </p>
                <p v-else-if="!landmark.long && landmark.short">
                    SAP only shows you have long landmark text right now so that is what will be
                    selected.
                </p>
                <div class="formField">
                    <input
                        id="long-landmark-radio"
                        v-model="selectedLandmark"
                        name="landmark-radio"
                        value="long"
                        type="radio"
                        :disabled="!landmark.long"
                    />
                    <label for="long-landmark-radio">Long landmark: {{ landmark.long }}</label>
                </div>
                <div class="formField">
                    <input
                        id="short-landmark-radio"
                        v-model="selectedLandmark"
                        name="landmark-radio"
                        value="short"
                        type="radio"
                        :disabled="!landmark.short"
                    />
                    <label for="short-landmark-radio">Short landmark: {{ landmark.short }}</label>
                </div>
                <div class="formField">
                    <input
                        id="long-and-short-landmark"
                        v-model="selectedLandmark"
                        name="landmark-radio"
                        value="both"
                        type="radio"
                        :disabled="!landmark.long || !landmark.short"
                    />
                    <label for="long-and-short-landmark">
                        Long and short landmark: {{ landmark.short }} - {{ landmark.long }}
                    </label>
                </div>
            </div>
        </div>
        <div slot="footer">
            <BaseBtn
                :text="saving ? 'Saving...' : 'Save'"
                :disabled="saving || selectedLandmark === landmark.selected"
                @click.native="save"
            ></BaseBtn>
        </div>
    </ChangeModal>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {},
    data() {
        return {
            selectedLandmark: 'long',
            saving: false,
        };
    },
    computed: {
        ...mapGetters(['office']),
        landmark() {
            return this.office.physicalAddress.landmark;
        },
    },

    created() {
        this.selectedLandmark = this.landmark.selected || 'long';
    },
    methods: {
        async save() {
            this.saving = true;
            const override = {
                value: this.selectedLandmark,
                status: 'approved',
                key: 'office.physicalAddress.landmark.selected',
            };
            this.$store
                .dispatch('SEND_CONTENT_OVERRIDE', override)
                .then(this.hideEditor)
                .then(() => {
                    this.saving = false;
                })
                .catch(() => {
                    // should do something with actual error
                    this.saving = false;
                    return this.$refs.modal.displayError(
                        new Error('problem creating the content override'),
                    );
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.formField {
    display: flex;
    align-items: center;
    margin-top: 5px;
}
label {
    align-items: center;
    display: flex;
    font-size: 16px;
    padding-left: 5px;
}

input[type='radio'] {
    appearance: none;
    width: 20px;
    height: 20px;
    margin: 0;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid $blue;
    transition: background 0.4s, border-color 0.4s, box-shadow 0.4s;

    &:disabled {
        border: 2px solid $dark-gray;
        cursor: not-allowed;
        & + label {
            opacity: 0.7;
            cursor: not-allowed;
        }
    }
    &:checked {
        background: $blue;
        box-shadow: inset 0 0 0 2px #fff;
    }
}
</style>
