<template>
    <ChangeModal
        :help="help"
        :helpcontext="{
            minHeight: 200,
            minWidth: 200,
            type: imageType == 'profile' ? 'Agent' : 'Team Member',
        }"
    >
        <span v-if="submitted" slot="title-prefix"></span>
        <span slot="title">{{ title }} {{ submitted ? 'Submitted' : '' }}</span>
        <span v-if="overwritePending" slot="description">
            Current pending {{ imageType }} image will be replaced.
        </span>
        <div v-if="$_pendingImage()" slot="editor">
            <h3 class="has-text-centered">
                This {{ imageType }} image is currently out for approval
            </h3>
            <div class="pending-image">
                <img alt="pending image" :src="$_pendingImage()" />
            </div>
        </div>
        <div v-else slot="editor">
            <div v-if="submitted" class="submitted-text">
                <p>
                    Thanks! Your new photo has been successfully submitted. It may take 1-2 business
                    days for review, and we'll e-mail the results.
                </p>
                <p>If your photo is approved it will go live immediately.</p>
            </div>
            <div v-else>
                <div class="body-content">
                    <div class="image">
                        <h4>Click below to upload:</h4>
                        <ImageEditor
                            ref="imageEditor"
                            :image-src="$store.getters.agent.image.profile"
                            :aspect-ratio="aspectRatio"
                            :circle-mask="circleMask"
                            :view-mode="1"
                            :image-passes.sync="imagePasses"
                            drag-mode="move"
                            @image-changed="imageReady = true"
                        />
                    </div>

                    <div class="instructions">
                        <div v-if="imageReady">
                            <h4>Adjust the image if needed:</h4>
                            <ul>
                                <li>
                                    Click &amp; Drag the
                                    <strong>inside</strong>
                                    the blue box to focus on the area of the photo you want to
                                    upload
                                </li>
                                <li>
                                    Click &amp; Drag the
                                    <strong>corners</strong>
                                    of the blue box to adjust the size of the uploaded image
                                </li>
                                <li>
                                    Use the buttons below the image to
                                    <strong>Rotate</strong>
                                    ,
                                    <strong>Zoom</strong>
                                    , etc.
                                </li>
                            </ul>
                        </div>

                        <h4>Ensure your photo meets these guidelines:</h4>
                        <div class="guidelines">
                            <ul>
                                <li v-for="g in guidelines" :key="g" class="field" v-html="g"></li>
                            </ul>
                            <label
                                v-if="imageReady && imagePasses"
                                v-tooltip="{
                                    content: imageReady
                                        ? 'My image meets the requirements'
                                        : 'Click the arrow to upload an image',
                                    position: 'bottom-start',
                                }"
                                class="checkbox"
                                :class="{ disabled: !imageReady || !imagePasses }"
                            >
                                <input
                                    v-if="imageReady"
                                    v-model="gl_checked"
                                    type="checkbox"
                                    :disabled="!imageReady"
                                />
                                I verify this photo meets the above guidelines.
                            </label>
                            <HelpPleaseNote>
                                This is a partial list of the most common guidelines &mdash; a photo
                                can be rejected for any violations of the
                                <a
                                    :href="`${publicPath}docs/Agency_Marketing_Information_Tool_-_Photo_FAQ_4-11-2018.docx`"
                                    target="_blank"
                                    aria-describedby="new-window-warning"
                                    rel="noopener noreferrer"
                                >
                                    Photo Compliance Guidelines
                                </a>
                                . We'll review the photo and send the results by e-mail within 2
                                business days.
                                <br />
                                <br />
                                Photos uploaded here, if approved
                                <strong>will not</strong>
                                change the
                                <HelpSAP />
                                or affect
                                <HelpMicrosite />
                                .
                            </HelpPleaseNote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="$_pendingImage()" slot="footer" class="pending-footer">
            <BaseBtn text="Cancel Changes" class="cancel" @click.native="hideEditor()" />
            <BaseBtn text="Pick a Different Image" @click.native="overwritePending = true" />
        </div>
        <div v-else slot="footer">
            <div v-if="submitted">
                <BaseBtn text="Close" @click.native="hideEditor()" />
            </div>
            <div v-else>
                <BaseBtn
                    v-if="gl_checked && !$refs.imageEditor.error"
                    :disabled="!gl_checked || saving"
                    :text="saving ? 'Saving...' : 'Submit for Approval'"
                    class="is-disabled"
                    @click.native="$_save"
                />
                <BaseBtn
                    v-if="$_notDefault()"
                    text="Revert to Default"
                    class="cancel"
                    @click.native="$_revertToDefault()"
                />
            </div>
        </div>
    </ChangeModal>
</template>

<script>
import ImageEditor from './../ImageEditor.vue';
import ChangeModal from './../ChangeModal.vue';
import { SaveImage } from './../mixins/SaveImage.js';

export default {
    components: { ChangeModal, ImageEditor },
    mixins: [SaveImage],
    props: {
        aid: { type: String, default: null },
        help: { type: String, default: null },
        helpcontext: { type: Object, default: null },
        title: { type: String, default: null },
        imageType: { type: String, default: null },
        guidelines: { type: Array, default: () => [] },
        keypath: { type: String, default: null },
        subkeypath: { type: String, default: null },
        circleMask: { type: Boolean, default: false },
        aspectRatio: { type: Number, default: 16 / 9 },
    },
    data() {
        const name = this.$store.getters.agent.names.PreferredName;
        return {
            error: null,
            submitted: false,
            imageReady: false,
            overwritePending: false,
            name: name,
            publicPath: process.env.BASE_URL,
            gl_checked: false,
            imagePasses: null,
        };
    },
};
</script>

<style lang="scss" scoped>
.pending-image {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        margin: 10px;
        align-self: center;
        max-width: 250px;
    }
}

.pending-footer {
    display: flex;
}

.body-content {
    display: flex;
    margin: auto;

    .instructions {
        margin-left: 10px;

        ul {
            padding-left: 30px;

            li {
                font-size: 14px;
                margin-left: 3px;
                padding: 0px;
                height: auto;
            }
        }

        input[type='checkbox'] {
            appearance: none;
            width: auto;
            margin-right: 10px;
            border: 1px solid gray;
            padding: 8px;

            &:checked {
                background-color: $blue;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='8 8 36 36' version='1.1' xml:space='preserve'%3E%3Cpath d='M14.1 27.2l7.1 7.2 16.7-16.8' fill='none' stroke='rgb%28255,255,255%29' stroke-width='5'/%3E%3C/svg%3E");
            }
        }

        .checkbox {
            color: $blue;
            margin-top: 20px;
            font-size: 20px;
        }

        .disabled {
            cursor: not-allowed !important;
            opacity: 0.5;
        }

        .fineprint {
            font-size: 14px;
            margin-top: 10px;
            color: $text;
        }
    }
}

.submitted-text {
    font-size: 24px;

    p {
        padding-bottom: 15px;
    }
}
</style>
