<template>
    <div class="card is-marginless is-paddingless">
        <div class="card-image">
            <BaseBtn
                v-if="expanded"
                class="expand-button is-hidden-xs"
                :text="selected ? 'Unselect this image' : 'Select this image'"
                @click.native="select"
            ></BaseBtn>
            <img v-tooltip="tooltip" alt="thumbnail image" :src="thumbUrl" @click="expand" />
        </div>
        <div class="card-content">
            <strong>Tags:</strong>
            <span
                v-for="tag in tagsList"
                :key="tag"
                class="search-tag"
                :class="{ active: isActive(tag) }"
                @click="toggleTag(tag)"
            >
                {{ tag.charAt(0).toUpperCase() + tag.slice(1) }}
            </span>
        </div>
        <transition name="checkmark">
            <svg
                v-if="selected"
                class="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
            >
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
        </transition>
        <div ref="scrollpoint" class="scroll-point"></div>
    </div>
</template>

<script>
import _ from 'lodash';
import { getResized } from '@/utils/ephemera.js';

export default {
    props: {
        url: { type: String, default: null },
        tags: { type: Array, default: null },
        publicid: { type: String, default: null },
        selected: { type: Boolean },
        expanded: { type: Boolean, default: false },
        context: {
            type: Object,
            default: function () {
                return {};
            },
        },
        search: { type: Array, default: null },
        tooltip: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {};
    },
    computed: {
        tagsList() {
            return this.tags;
        },
        thumbUrl() {
            if (this.publicid === 'default') {
                return this.url;
            }
            if (this.expanded) {
                return getResized(this.url, 1200);
            }
            return getResized(this.url, 500);
        },
    },
    methods: {
        expand() {
            this.$emit('expand');
        },
        select() {
            this.$emit('imageClicked');
        },
        isActive(tag) {
            let searchTags = this.search;
            if (searchTags != '') {
                return _.find(searchTags, (stag) => stag.name.toLowerCase() == tag.toLowerCase());
            }
        },
        toggleTag(tag) {
            this.$emit('toggle', tag);
        },
    },
};
</script>

<style lang="scss">
.scroll-point {
    position: absolute;
    top: -50px;
}

.card {
    margin-bottom: 5px;
    position: relative;
    transition: box-shadow 100ms ease-in-out;

    &:hover {
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.6);
    }

    img {
        cursor: pointer;
        margin: auto;
        width: 100%;
    }
}

.expand-button {
    position: absolute;
    bottom: 9%;
    right: 0;
    margin: 5px;
    padding: 7px;
    border-radius: 0;
}

.card-content {
    font-size: 10px;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;

    .search-tag {
        background: hsl(0, 0%, 90%);
        border-radius: 3px;
        color: hsl(0, 0%, 21%);
        cursor: pointer;
        padding: 2px 5px;
        margin: 1px;

        &:hover {
            box-shadow: 0 0 7px rgba(0, 0, 0, 0.6);
        }
    }

    .active {
        background: $orange;
        color: #fff;
    }

    strong {
        margin: auto 1px;
    }
}

/* checkmark circle */
$color--green: $green;
$curve: cubic-bezier(0.65, 0, 0.45, 1);

.checkmark {
    position: absolute;
    top: -20px;
    right: -10px;
    width: 56px;
    height: 56px;
    border-radius: 50%;

    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px $color--green;
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;

    circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: $color--green;
        fill: $color--green;
        animation: stroke 0.6s $curve forwards;
    }

    check {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: stroke 0.3s $curve 0.8s forwards;
    }

    &.selected {
        visibility: visible;
    }
}
/* checkmark circle end */

.checkmark-enter-active,
.checkmark-leave-active {
    transition: opacity 0.5s;
}
.checkmark-enter, .checkmark-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
