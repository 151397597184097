<template>
    <ChangeModal ref="modal" help="HelpArticles">
        <span slot="title">Desjardins Insurance blog</span>
        <span slot="description">
            Hide specific blog articles from displaying on your Enhanced Microsite
        </span>
        <span slot="editor">
            <h3>Your Current Articles</h3>

            <div v-for="article in current" :key="article.title" class="article-option">
                <div class="article-wrapper">
                    <BaseArticle :item="article" :class="{ blacklisted: article.blacklisted }" />
                    <HelpPleaseNote v-if="article.blacklisted" class="note">
                        this article will be replaced on save.
                    </HelpPleaseNote>
                </div>
                <button
                    v-if="article.blacklisted"
                    class="add"
                    @click="removeFromBlacklist(article)"
                >
                    Show Article
                </button>
                <button
                    v-else
                    :disabled="blacklist === null"
                    class="remove"
                    @click="addToBlacklist(article)"
                >
                    Hide Article
                </button>
            </div>

            <h3 class="blacklist-header">Hidden Articles</h3>

            <div v-if="blacklist" class="blacklist">
                <div v-for="article in blacklist" :key="article.title" class="article-option">
                    <div class="article-wrapper">
                        <BaseArticle
                            :item="article"
                            :class="{ blacklisted: article.whitelisted }"
                            class="article-wrapper"
                        />
                    </div>
                    <button
                        v-if="article.whitelisted"
                        class="remove"
                        @click="addToBlacklist(article)"
                    >
                        Undo
                    </button>
                    <button v-else class="add" @click="removeFromBlacklist(article)">Unhide</button>
                </div>
            </div>
            <div v-else class="center">
                <Spinner size="50" />
            </div>
            <HelpPleaseNote>
                Once you Unhide an article, it will be added back into the rotation of possible
                articles which are refreshed weekly. Unhide will
                <strong>not</strong>
                make those articles immediately visible on your Enhanced Microsite.
            </HelpPleaseNote>
        </span>
        <span slot="footer">
            <BaseBtn
                :disabled="saving || unchanged"
                :text="saving ? 'Saving' : 'Save Changes'"
                @click.native="save"
            />
        </span>
    </ChangeModal>
</template>

<script>
import axios from 'axios';
import { cloneDeep, filter, findIndex, isEqual, map, merge } from 'lodash';
import { mapGetters } from 'vuex';

import Spinner from '@/components/Shared/Spinner.vue';
import BaseArticle from '@/components/SectionPanels/BaseArticle.vue';
import HelpPleaseNote from '@/components/Editor/help/bits/HelpPleaseNote.vue';
export default {
    components: { BaseArticle, HelpPleaseNote, Spinner },
    data() {
        return {
            saving: false,
            originalBlacklist: null,
            blacklist: null,
            current: null,
        };
    },
    computed: {
        ...mapGetters(['apiHost', 'articles', 'meta']),
        blacklistApiUrl() {
            return `${this.apiHost}/backend/api/v1/amit/blacklist/articles/${this.meta.domain}/`;
        },
        unchanged() {
            if (this.blacklist === null) return false;
            return isEqual(
                map(this.originalBlacklist, 'title'),
                map(
                    filter(this.blacklist, (a) => a.whitelisted !== true),
                    'title',
                ),
            );
        },
    },
    mounted() {
        this.current = cloneDeep(this.articles);
        axios({
            method: 'get',
            url: this.blacklistApiUrl,
            xsrfHeaderName: 'X-CSRFToken',
            xsrfCookieName: 'csrftoken',
            withCredentials: true,
        }).then((resp) => {
            this.blacklist = cloneDeep(resp.data);
            this.originalBlacklist = cloneDeep(resp.data);
        });
    },
    methods: {
        addToBlacklist(article) {
            const ci = findIndex(this.current, (a) => a.title === article.title);
            const bi = findIndex(this.blacklist, (a) => a.title === article.title);

            if (bi >= 0) {
                this.$set(this.blacklist, bi, merge(this.blacklist[bi], { whitelisted: false }));
            } else {
                this.blacklist.push(this.current[ci]);
                this.$set(this.current, ci, merge(this.current[ci], { blacklisted: true }));
            }
        },
        removeFromBlacklist(article) {
            const ci = findIndex(this.current, (a) => a.title === article.title);
            const bi = findIndex(this.blacklist, (a) => a.title === article.title);

            if (ci >= 0) {
                this.$set(this.current, ci, merge(this.current[ci], { blacklisted: false }));
                this.$delete(this.blacklist, bi);
            } else {
                this.$set(this.blacklist, bi, merge(this.blacklist[bi], { whitelisted: true }));
            }
        },
        save() {
            this.saving = true;
            axios({
                method: 'put',
                url: this.blacklistApiUrl,
                data: {
                    articles_blacklist: map(
                        filter(this.blacklist, (a) => a.whitelisted !== true),
                        (a) => a.title,
                    ),
                },
                xsrfHeaderName: 'X-CSRFToken',
                xsrfCookieName: 'csrftoken',
                withCredentials: true,
            })
                .then(this.hideEditor)
                .then(() => (this.saving = false))
                .then(() => location.reload())
                .catch(() => {
                    this.saving = false;
                    return this.$refs.modal.displayError(
                        new Error('problem updating the articles blacklist'),
                    );
                });
        },
    },
};
</script>

<style lang="scss">
.center {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.blacklist-header {
    margin-top: 1em;
    margin-bottom: 0.75em !important;
}

.blacklist {
    max-height: 320px;
    overflow-y: scroll;
}

.article-option {
    border-top: 2px solid $gray;
    opacity: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .article-wrapper {
        width: 80%;

        .note {
            margin-left: 20px;
        }

        .blacklisted {
            opacity: 0.3;
        }
    }

    button {
        margin: 10px;
        padding: 10px;
        font-size: 16px;
        color: white;
        border: none;
        transition: background-color 0.2s;

        &.add {
            background-color: $green;

            &:hover {
                background-color: $dark-green;
            }
        }

        &.remove {
            background-color: $red;

            &:disabled {
                background-color: $gray;
            }

            &:enabled {
                &:hover {
                    background-color: darken($red, 10%);
                }
            }
        }
    }
}
</style>
