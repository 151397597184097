<template>
    <div class="helpblock columns is-multiline">
        <div class="column is-12 purpose">
            <h6>What is this thing?</h6>
            <slot>Purpose</slot>
        </div>

        <div v-if="$slots.troubleshooting" class="column is-12 troubleshooting">
            <h6>Troubleshooting</h6>
            <slot name="troubleshooting">Troubleshooting</slot>
        </div>

        <div class="column is-6 details">
            <h6>How can you edit it?</h6>

            <p v-if="editnow === true">
                You can edit what is shown on your Enhanced Microsite (EM) right now by clicking the
                <strong>Edit</strong>
                button below.
            </p>

            <p v-if="editnow === true">
                <em>
                    Any changes made via the EM Editor will have precedence on this site only and
                    <strong>will not</strong>
                    alter any data on the default microsite or in SAP.
                </em>
            </p>
            <p v-else-if="editnow === false">
                You cannot edit this item directly on your Enhanced Microsite at this time.
                <span
                    v-if="
                        (editaction || $slots.editing) &&
                        editaction !== 'none' &&
                        editaction !== 'learningcenter'
                    "
                >
                    However, you can edit it elsewhere:
                </span>
            </p>

            <p v-if="editaction === 'sap'">
                You can also edit this information in
                <HelpSAP />
                .
                <br />
                <HelpPleaseNote>
                    Edits made in SAP will also affect
                    <HelpMicrosite />
                    .
                </HelpPleaseNote>
            </p>

            <p v-if="editaction === 'learningcenter'">
                However, you don't need to worry about editing Learning Center articles yourself,
                because we will automatically detect when new articles are available and put them
                into the rotation of articles which will appear on your microsite.
            </p>

            <div v-if="$slots.editing">
                <slot name="editing"></slot>
            </div>
        </div>

        <div v-if="$slots.source || source" class="column is-6 details">
            <h6>Where did we get the information?</h6>
            <p v-if="source === 'sap'">
                This information originally comes from your
                <HelpSAP />
                data. We receive an updated copy of your SAP data once per day, and changes
                affecting the SAP typically arrive within 24-72 hours.
            </p>
            <div v-if="$slots.source">
                <slot name="source">Source</slot>
            </div>
        </div>

        <div v-if="$slots.additional" class="column is-12 details">
            <h6>Additional Info</h6>
            <p>
                <slot name="additional"></slot>
            </p>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import bits from './bits/AllBits';
Object.keys(bits).forEach((key) => {
    Vue.component(key, bits[key]);
});

export default {
    props: {
        editnow: { type: [String, Boolean], default: false },
        editaction: { type: String, default: null },
        source: { type: String, default: null },
        subject: { type: String, default: null },
    },
    data() {
        return {};
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.helpblock {
    font-family: $base-font;
    .tbd {
        font-size: 16px;
        color: $dark;
        background-color: #f6df79;
        padding: 15px;
    }

    b {
        font-family: $base-font;
        color: $darker;
    }
    ol,
    ul {
        // list-style-position: inside;
        margin: 10px 0 15px 10px;
        li {
            margin-left: 15px;
        }
    }

    h6 {
        font-family: $base-font;
        font-size: 18px;
        border-bottom: dashed 1px $light-blue;
        margin-bottom: 4px;
    }
    .purpose {
        p {
            margin-left: 15px;
        }
        p + p {
            margin-top: 10px;
        }
    }
    .details {
        p {
            margin-left: 15px;
            font-size: 14px;
        }
    }
}
</style>
