<template>
    <ChangeModal ref="modal" help="HelpMission">
        <span slot="title">Mission Statement</span>
        <span slot="description">
            Explain the aims and values of your office, and what makes it great!
        </span>
        <span slot="editor">
            <EditTextArea
                :text.sync="mission"
                :results.sync="compliance"
                placeholder="Our Mission Statement..."
            ></EditTextArea>
        </span>
        <div slot="footer">
            <BaseBtn
                v-tooltip="$_compliancyError(!canSave())"
                :text="saveButtonText"
                :disabled="saving || !canSave()"
                @click.native="save"
            />
        </div>
    </ChangeModal>
</template>

<script>
import EditTextArea from './../EditTextArea.vue';
import { Tooltips } from './../mixins/Tooltips.js';

export default {
    label: 'Mission Statement',
    components: { EditTextArea },
    mixins: [Tooltips],
    data() {
        return {
            mission: this.$store.getters.office.mission,
            compliance: null,
            changed: false,
            saving: false,
        };
    },
    computed: {
        saveButtonText() {
            if (this.compliance && this.compliance.status === 'pending') {
                return 'Checking...';
            } else if (this.saving) {
                return 'Saving';
            } else {
                return 'Save Changes';
            }
        },
    },
    mounted() {},
    methods: {
        save() {
            this.saving = true;
            return this.$store
                .dispatch('SEND_CONTENT_OVERRIDE', {
                    value: this.mission,
                    status: 'approved',
                    key: 'office.mission',
                })
                .then(this.hideEditor)
                .then(() => {
                    this.saving = false;
                })
                .catch(() => {
                    // should do something with actual error
                    this.saving = false;
                    return this.$refs.modal.displayError(
                        new Error('problem creating the content override'),
                    );
                });
        },
        canSave() {
            return (
                this.compliance &&
                this.compliance.status === 'passed' &&
                this.compliance.value === this.mission
            );
        },
    },
};
</script>
