<template>
    <ChangeModal ref="modal">
        <span slot="title">Session Expired!</span>
        <span slot="editor">
            <h2>Oh no!</h2>
            <p>
                It looks like your editing session has expired. You'll need to login again to
                continue editing. We'll try and automatically do that in {{ count }} seconds.
            </p>
        </span>
        <span slot="footer" class="buttons">
            <BaseBtn text="Login" @click.native="relogin" />
            <BaseBtn text="Exit Editor" class="cancel" @click.native="logout" />
        </span>
    </ChangeModal>
</template>

<script>
export default {
    label: 'Session Expired',
    data() {
        return {
            count: 10,
            timer: null,
        };
    },
    mounted() {
        this.countDown();
    },
    beforeDestroy() {
        clearTimeout(this.timer);
    },
    methods: {
        logout() {
            this.$store.commit('LOGOUT');
        },
        relogin() {
            const inBrowser = typeof window !== 'undefined';
            if (inBrowser) {
                this.logout();
                window.location.href = `${
                    this.$store.getters.apiHost
                }/redirect?destination=${encodeURIComponent(window.location)}`;
            }
        },
        countDown() {
            if (this.count < 1) {
                this.relogin();
            } else {
                this.timer = setTimeout(() => {
                    this.count -= 1;
                    this.countDown();
                }, 1000);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.buttons {
    display: flex;
}

h2 {
    text-align: center;
}

p {
    text-align: center;
    padding: 20px;
    font-size: 18px;
}
</style>
