<template>
    <ChangeModal ref="modal" help="HelpGoogleReview">
        <span slot="title">Google Review</span>
        <span slot="description">
            Display the Google icon and "Review us on Google" on your EM site.
        </span>
        <span slot="editor">
            <h2>Review us on Google</h2>
            <div class="toggle-line">
                <EditSwitch
                    :checked.sync="visible"
                    :disabled="false"
                    on-text=""
                    off-text=""
                    class="switch"
                />
                <span class="name">Google</span>
                <span :class="['icon', { 'is-disabled': !visible }]">
                    <svgicon icon="google-review" width="27" height="27" :orginal="true" />
                </span>
            </div>
        </span>
        <span slot="footer">
            <BaseBtn :disabled="saving || !canSave()" :text="saveButtonText" @click.native="save" />
        </span>
    </ChangeModal>
</template>

<script>
import { mapGetters } from 'vuex';
import EditSwitch from './../EditSwitch.vue';

export default {
    label: 'Google Review',
    components: { EditSwitch },
    data() {
        return {
            visible: this.$store.getters.ui.review.visible,
            saving: false,
        };
    },
    computed: {
        ...mapGetters(['ui']),
        saveButtonText() {
            if (this.saving) {
                return 'Saving';
            } else {
                return 'Save Changes';
            }
        },
    },
    methods: {
        save() {
            this.saving = true;
            return this.$store
                .dispatch('SEND_CONTENT_OVERRIDE', {
                    value: this.visible,
                    status: 'approved',
                    key: 'ui.review.visible',
                })
                .then(this.hideEditor)
                .then(() => {
                    this.saving = false;
                })
                .catch(() => {
                    // should do something with actual error
                    this.saving = false;
                    return this.$refs.modal.displayError(
                        new Error('problem creating the content override'),
                    );
                });
        },
        canSave() {
            return this.ui.review.visible != this.visible;
        },
    },
};
</script>

<style lang="scss" scoped>
.toggle-line {
    display: flex;
    align-items: center;
    margin: 10px 0;
    width: 250px;

    .switch {
        margin-top: 4px;
        flex-grow: 1;
    }

    .name {
        flex-grow: 3;
    }

    .icon {
        flex-grow: 2;
    }
}

.is-disabled {
    opacity: 0.5;
}
</style>
