<template>
    <span v-if="editingAllowed">
        <switches
            v-model="isChecked"
            theme="custom"
            :disabled="disabled"
            :color="color()"
        ></switches>
        <span :class="statusClass()" class="switch-status">
            {{ status() }}
        </span>
    </span>
</template>

<script>
import Switches from 'vue-switches';
export default {
    components: { Switches },
    props: {
        onText: {
            type: String,
            default: 'On',
        },
        offText: {
            type: String,
            default: 'Off',
        },
        checked: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        checkedColor: {
            type: String,
            default: 'blue',
        },
    },
    data() {
        return {
            isChecked: this.checked || false,
        };
    },
    watch: {
        isChecked(v) {
            this.$emit('update:checked', v);
        },
    },
    methods: {
        color() {
            if (this.disabled) {
                return 'red';
            }
            return this.isChecked ? this.checkedColor : 'unchecked';
        },
        status() {
            if (this.disabled) {
                return 'Disabled';
            }
            return this.isChecked ? this.onText : this.offText;
        },
        statusClass() {
            if (this.disabled) {
                return 'disabled ';
            }
            return this.isChecked ? `checked ${this.checkedColor}` : '';
        },
    },
};
</script>
