<template>
    <BaseBtn
        text="Help"
        :icon="{
            color: 'white',
            height: '15',
            name: 'help',
            width: '15',
        }"
        class="modeBtn help"
        @click.native="toggleHelp(force, editor)"
    />
</template>

<script>
export default {
    props: {
        force: { type: Boolean, default: true },
        editor: { type: String, default: undefined },
    },
};
</script>

<style lang="scss" scoped>
.modeBtn {
    padding: 3px 10px;
    font-weight: normal;
    &.help {
        color: #fff;
        background-color: $blue;
        &:hover {
            background-color: lighten($blue, 10%);
        }
    }
}
</style>
