<template>
    <div class="editTextArea">
        <span slot="title"></span>
        <div id="wysiwyg" class="pell" @paste="handlePaste"></div>

        <div v-if="errors.length > 0" class="compliance errors">
            <p>You have compliance errors in your text.</p>
            <ul>
                <li v-for="(error, index) in errors" :key="index">
                    {{ error.description }}
                </li>
            </ul>
        </div>
        <div v-else-if="tcResults.status === 'pending'" class="compliance pending">
            Checking Compliance...
        </div>
        <div v-else-if="tcResults.status === 'passed'" class="compliance compliant">
            This text is compliant.
        </div>
        <div v-else-if="tcResults.status === 'error'" class="compliance errors">
            There was an error running the compliance check, please try again later.
        </div>
        <br />
    </div>
</template>

<script>
import axios from 'axios';
import pell from 'pell';
import { mapGetters } from 'vuex';
import 'pell/dist/pell.min.css';
import _ from 'lodash';

export default {
    props: {
        text: { type: String, default: null },
        placeholder: { type: String, default: null },
        results: { type: String, default: null },
    },
    data() {
        return {
            tab: 'edit',
            localtext: '',
            tcResults: {},
            interval: null,
            errors: [],
            waitedForPause: false,
            timer: null,
            wrapper: null,
        };
    },
    computed: {
        ...mapGetters(['apiUrl', 'meta', 'editor']),
        strippedText() {
            return this.localtext.replace(/<[^>]*>/g, '');
        },
    },
    watch: {
        localtext(v) {
            this.waitedForPause = false;
            if (!_.isNil(this.timer)) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => (this.waitedForPause = true), 2000);
            this.$emit('update:text', v);
        },
        waitedForPause(v) {
            if (v) {
                this.check();
            } else {
                this.tcResults = {
                    status: 'changed',
                    value: this.localtext,
                };
            }
        },
        tcResults(v) {
            this.$emit('update:results', v);
        },
    },
    created() {
        this.localtext = this.text;
    },
    mounted() {
        const wysiwyg = pell.init({
            element: document.getElementById('wysiwyg'),
            actions: ['bold', 'italic', 'ulist'],
            onChange: () => {},
        });

        // TODO: implement better I.E. fix.
        wysiwyg.content.innerHTML = this.localtext;
        this.interval = setInterval(() => {
            this.localtext = document
                .querySelector('.pell-content')
                .innerHTML.replace(/<div><br><\/div>/g, '<br>');
        }, 10);
    },
    destroyed() {
        clearInterval(this.interval);
    },
    methods: {
        check() {
            const self = this;
            const text = this.localtext;
            this.tcResults = {
                status: 'pending',
                value: this.localtext,
            };
            axios({
                method: 'POST',
                url: `${this.apiUrl}/compliance/check/`,
                xsrfHeaderName: 'X-CSRFToken',
                xsrfCookieName: 'csrftoken',
                withCredentials: true,
                data: { text, domain: this.meta.domain },
                timeout: 5000,
            })
                .then((response) => {
                    // console.log('resp.data',response.data);

                    const identity_id = _.get(self.editor, 'user.email_address');
                    const identity_organization = _.get(self.editor, 'user.user_type');

                    if (response.status !== 200) {
                        this.$keen('compliancecheck', {
                            api_response: response.status,
                            original_text: text,
                            error: response.data.detail,
                            identity_id,
                            identity_organization,
                        });
                        return;
                    }

                    self.errors = response.data;

                    self.tcResults = {
                        status: self.errors.length > 0 ? 'failed' : 'passed',
                        value: text,
                    };

                    const violations = _.flatten(
                        self.errors.map((i) => {
                            return i.matches.map((m) => ({ category: i.regex, text: m.text }));
                        }),
                    );

                    this.$keen('compliancecheck', {
                        api_response: response.status,
                        disposition: self.tcResults.status,
                        is_compliant: self.errors.length === 0,
                        original_text: text,
                        violations,
                        identity_id,
                        identity_organization,
                    });

                    return text;
                })
                .catch((err) => {
                    self.tcResults = {
                        status: 'error',
                        value: text,
                    };
                    if (err.response) {
                        if (err.response.status === 401 || err.response.status === 403) {
                            this.$parent.$parent.$refs.modal.sessionExpired();
                        }
                    }
                    /* this.$sentry.captureException(err); */
                    this.$parent.$parent.$refs.modal.displayNotification(
                        `Error: ${err.message}`,
                        'error',
                        15000,
                    );
                    const response = err.response || { data: {} };
                    this.$keen('compliancecheck', {
                        api_response: response.status,
                        original_text: text,
                        error: response.data.detail,
                    });
                });
        },
        formatted() {
            let t = (this.localtext || '').trim();
            return this.$options.filters.paraFormat(t);
        },
        handlePaste() {
            // TODO notifications should be handled through the store so we don't have to write code like this
            this.$parent.$parent.$refs.modal.displayNotification(
                'We noticed you pasted some text. We can only display certain types of text on your EM so the text you copied may look slightly different in our editor.',
                'info',
                15000,
            );
            setTimeout(() => {
                this.sanatize();
            }, 10);
        },
        sanatize() {
            // remove style/script
            this.localtext = this.localtext.replace(
                /<(style|script) [\s\S]*>[\s\S]*<\/(style|script)>/gi,
                '',
            );
            // remove attrs
            this.localtext = this.localtext.replace(/(<\w*\b)(.*?)(>)/gi, '$1$3');
            // remove unapproved tags
            this.localtext = this.localtext.replace(
                /<\/?(?!\/?div>|\/?i>|\/?br?>|\/?ul>|\/?li>)(\w*).>/gi,
                (match) => (match.includes('/') ? '</div>' : '<div>'),
            );
            document.querySelector('.pell-content').innerHTML = this.localtext;
        },
    },
};
</script>

<style lang="scss">
.editTextArea {
    .pell-content {
        font-size: 16px;
        ul {
            margin-left: 30px;
            margin-bottom: 20px;
        }
    }
}

.compliance {
    margin-left: 5px;
    margin-top: 15px;
    font-size: 18px;

    &.compliant {
        color: $green;
    }
    &.pending {
        colod: $black;
    }

    &.errors {
        color: $red;
    }

    ul {
        margin-left: 30px;
    }
}
</style>
