<template>
    <ChangeModal ref="modal" help="HelpAboutMe">
        <span slot="title">About Me</span>
        <span slot="description">
            Tell us a bit about yourself and why you love being an agent!
        </span>
        <span slot="editor">
            <EditTextArea
                :results.sync="compliance"
                :text.sync="about_me"
                placeholder="About Me..."
            />
        </span>
        <span slot="footer">
            <BaseBtn
                v-tooltip="$_compliancyError(!canSave())"
                :disabled="saving || !canSave()"
                :text="saveButtonText"
                @click.native="save"
            />
        </span>
    </ChangeModal>
</template>

<script>
import EditTextArea from './../EditTextArea.vue';
import { Tooltips } from './../mixins/Tooltips.js';

export default {
    label: 'About Me',
    components: { EditTextArea },
    mixins: [Tooltips],
    data() {
        return {
            about_me: this.$store.getters.agent.about,
            compliance: null,
            saving: false,
        };
    },
    computed: {
        saveButtonText() {
            if (this.compliance && this.compliance.status === 'pending') {
                return 'Checking...';
            } else if (this.saving) {
                return 'Saving';
            } else {
                return 'Save Changes';
            }
        },
    },
    methods: {
        save() {
            this.saving = true;
            return this.$store
                .dispatch('SEND_CONTENT_OVERRIDE', {
                    value: this.about_me,
                    status: 'approved',
                    key: 'agent.about',
                })
                .then(this.hideEditor)
                .then(() => {
                    this.saving = false;
                })
                .catch(() => {
                    // should do something with actual error
                    this.saving = false;
                    return this.$refs.modal.displayError(
                        new Error('problem creating the content override'),
                    );
                });
        },
        canSave() {
            return (
                this.compliance &&
                this.compliance.status === 'passed' &&
                this.compliance.value === this.about_me
            );
        },
    },
};
</script>
