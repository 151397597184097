<template>
    <ChangeModal ref="modal" help="HelpTeam">
        <span slot="title">Team Description &amp; Visibility</span>
        <span slot="description">
            Explain the aims and values of your team members, and how they make your office excel!
        </span>
        <span slot="editor">
            <div class="team-content">
                <span class="sub-header">
                    <h4 class="has-text-right">
                        Team Tab Visibility:
                        <EditSwitch
                            :checked.sync="tabSettings.visible"
                            :disabled="false"
                            on-text="Visible"
                            off-text="Hidden"
                        />
                    </h4>
                    <h3>Description</h3>
                </span>

                <EditTextArea
                    :text.sync="description"
                    :results.sync="compliance"
                    placeholder="Our Team Description..."
                />

                <div>
                    <h3>Team Visibility Options</h3>
                    <div class="teamMembers">
                        <div
                            v-for="member in members"
                            :key="member.names.NickName || member.names.LegalName"
                            class="member"
                        >
                            <div class="content">
                                <img
                                    :alt="member.names.NickName || member.names.LegalName"
                                    :src="member.image.profile"
                                    class="image"
                                    @error="setPlaceholderImage"
                                />
                                <div class="text">
                                    <h4>{{ member.names.NickName || member.names.LegalName }}</h4>
                                    <EditSwitch
                                        :checked.sync="member.visible"
                                        on-text="Visible"
                                        off-text="Hidden"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <div slot="footer">
            <BaseBtn :disabled="!canSave" :text="saveButtonText" @click.native="saveAll" />
        </div>
    </ChangeModal>
</template>

<script>
import { mapGetters } from 'vuex';
import EditSwitch from './../EditSwitch.vue';
import EditTextArea from './../EditTextArea.vue';

import { get as _get, cloneDeep, isEqual, isEmpty, has, each, filter } from 'lodash';
import { Tooltips } from './../mixins/Tooltips.js';
import noImage from '@/assets/img/no-profile-avatar.jpg';

export default {
    label: 'Team Description',
    components: { EditSwitch, EditTextArea },
    mixins: [Tooltips],
    data() {
        return {
            noImage,
            saving: false,
            compliance: null,
            tabSettings: cloneDeep(_get(this.$store.getters.ui, 'tabs.team', { visible: true })),
            members: each(cloneDeep(_get(this.$store.getters.team, 'members', null)), (member) => {
                if (!has(member, 'visible')) {
                    member.visible = true;
                }
            }),
            description: cloneDeep(_get(this.$store.getters.team, 'description', '')),
        };
    },
    computed: {
        ...mapGetters(['team', 'ui']),
        canSave() {
            if (this.saving || this.unchanged) {
                return false;
            }
            if (this.descriptionChanged) {
                if (this.description === '') {
                    return false;
                }
                if (!this.descriptionCompliant) {
                    return false;
                }
                return true;
            }
            if (this.tabVisibilityChanged) {
                return true;
            }
            return this.membersChanged;
        },
        changedMembers() {
            return filter(this.members, (member) => {
                const originalMember = this.team.members.find((m) => m.aid === member.aid);
                return !isEqual(member, originalMember);
            });
        },
        descriptionChanged() {
            const originalDescription = _get(this.team, 'description', '');
            if (isEmpty(this.description) && isEmpty(originalDescription)) {
                return false;
            }
            return this.description !== originalDescription;
        },
        descriptionCompliant() {
            return (
                this.compliance &&
                this.compliance.status === 'passed' &&
                this.compliance.value === this.description
            );
        },
        membersChanged() {
            return !isEqual(this.members, _get(this.team, 'members'));
        },
        saveButtonText() {
            if (this.compliance && this.compliance.status === 'pending') {
                return 'Checking...';
            } else if (this.saving) {
                return 'Saving';
            } else {
                return 'Save Changes';
            }
        },
        tabVisibilityChanged() {
            return !isEqual(this.tabSettings, _get(this.ui, 'tabs.team', { visible: true }));
        },
        unchanged() {
            return !(this.tabVisibilityChanged || this.descriptionChanged || this.membersChanged);
        },
    },
    methods: {
        save(type, value, key, subkey = undefined) {
            return this.$store
                .dispatch('SEND_CONTENT_OVERRIDE', {
                    value,
                    status: 'approved',
                    key,
                    subkey,
                })
                .then(() => {
                    this.$refs.modal.displayNotification(`${type} changes saved`, 'success');
                });
        },
        saveAll() {
            this.saving = true;
            let promises = [];

            if (this.tabVisibilityChanged) {
                promises.push(this.save('Team Visibility', this.tabSettings, 'ui.tabs.team'));
            }

            if (this.descriptionChanged) {
                promises.push(this.save('Team Description', this.description, 'team.description'));
            }

            if (this.membersChanged) {
                for (let member of this.changedMembers) {
                    promises.push(
                        this.save(
                            'Team Member',
                            member.visible,
                            `team.members`,
                            `${member.aid}.visible`,
                        ),
                    );
                }
            }

            return Promise.all(promises)
                .then(() => {
                    this.saving = false;
                })
                .then(this.hideEditor)
                .catch((err) => {
                    console.error(err);
                    this.saving = false;
                    this.$refs.modal.displayError(
                        new Error('There was a problem saving your changes! Please try later.'),
                    );
                });
        },
        setPlaceholderImage(event) {
            event.target.src = noImage;
        },
    },
};
</script>

<style lang="scss" scoped>
.sub-header {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 25px 0 20px;

    .has-text-right {
    }
}

image {
    flex-shrink: 0;
    margin-right: 12px;
}

.teamMembers {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: 0px 12px;

    .member {
        flex-basis: 25%;
        padding: 5px;

        .content {
            margin: 0px;
            width: 100%;
            margin: 2px;
            display: flex;
            padding: 8px;

            -webkit-transition: box-shadow 0.1s; /* safari */
            transition: box-shadow 0.1s;

            .image {
                padding-right: 10px;
                height: 70px;
                max-width: 67px;
            }

            /* TODO: use when we add reordering */
            /* &:hover {
               border: 2px solid darken($light-blue, 10%);
               margin: 0px;
               } */
        }

        @include media('<=xl') {
            flex: 0 0 33.333%;
        }

        @include media('<=md') {
            flex: 0 0 100%;
        }
    }
}
</style>
