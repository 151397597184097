export const Tooltips = {
    data() {},
    methods: {
        $_error(show, message) {
            return {
                content: show && message,
                classes: ['error'],
                offset: 10,
                placement: 'top',
            };
        },
        $_compliancyError(show) {
            return this.$_error(show, 'Your text must be compliant before saving');
        },
    },
};
