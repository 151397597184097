<template>
    <ChangeModal
        ref="modal"
        :helpcontext="{ minWidth: bannerMinWidth, minHeight: bannerMinHeight }"
        help="HelpBannerImage"
    >
        <span v-if="submitted" slot="title-prefix"></span>
        <span slot="title">Banner Image {{ submitted ? 'Submitted' : '' }}</span>

        <span v-if="overwritePending && !submitted" slot="description">
            Current pending image will be replaced.
        </span>
        <span v-else slot="description"></span>
        <div v-if="$_pendingImage()" slot="editor" ref="body">
            <h3 class="has-text-centered">This banner image is currently out for approval</h3>
            <div class="pending-image">
                <img class="lazyload" :data-src="$_pendingImage()" alt="banner image" />
            </div>
        </div>
        <div v-else slot="editor" ref="body">
            <div v-if="!uploadBanner">
                <div class="banner-header">
                    <div class="image-search-text">
                        <div class="search-wrapper">
                            <div>
                                <h4>Pick from {{ images.length || '' }} pre-approved banners:</h4>
                            </div>
                            <div>
                                <multiselect
                                    ref="imageEditor"
                                    v-model="search"
                                    class="search-input"
                                    tag-placeholder="Add this as new tag"
                                    placeholder="Type or click here to search"
                                    label="name"
                                    track-by="code"
                                    :options="options"
                                    :multiple="true"
                                    @tag="addTag"
                                ></multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="or">OR</div>
                    <!-- need the spaces around the OR -->
                    <div class="custom-upload-header">
                        <h4>Upload your own custom banner:</h4>
                        <img
                            alt="click to upload a banner image"
                            src="@/assets/img/custom-banner-upload.png"
                            @click="uploadBanner = true"
                        />
                    </div>
                </div>
                <div ref="bannerlist" class="banners-list">
                    <transition-group
                        tag="div"
                        name="images"
                        class="row"
                        @scroll="scrollingImages()"
                    >
                        <div
                            v-for="(image, index) in filteredImages"
                            :key="image.url"
                            ref="card"
                            :class="{
                                'col-sm-6': !isExpanded(image.url),
                                'col-lg-4': !isExpanded(image.url),
                                'col-12': isExpanded(image.url),
                            }"
                        >
                            <ImageCard
                                :search="search"
                                :publicid="image.public_id"
                                :url="image.url"
                                :tags="image.tags"
                                :selected="isSelected(image.url)"
                                :expanded="isExpanded(image.url)"
                                :tooltip="{
                                    content:
                                        expanded == image.url
                                            ? 'Click to shrink'
                                            : 'Click to expand',
                                    placement: 'bottom',
                                }"
                                :context="getScrollContext()"
                                @imageClicked="setSelectedImage(image)"
                                @expand="setExpandedImage(image, index)"
                                @toggle="toggleTag"
                            />
                        </div>
                    </transition-group>
                    <div class="load-more">
                        <!-- <span class="loader" v-if="loading || loadingOverride"></span> -->
                        <p v-if="(loading || loadingOverride) && !allImagesLoaded">
                            Loading images...
                        </p>
                        <BaseBtn
                            v-if="!loading && !allImagesLoaded && !loadingOverride"
                            class="load-more-btn"
                            text="Load more"
                            @click.native="displayNextImageBatch"
                        />
                        <p v-if="allImagesLoaded">All images loaded.</p>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="submitted" class="submitted-text">
                    <p>
                        Thanks! Your new banner photo has been successfully submitted. It may take
                        1-2 business days for review, and we'll e-mail the results.
                    </p>
                    <p>If your photo is approved it will go live immediately.</p>
                </div>
                <div v-else class="body-content">
                    <div class="columns">
                        <div class="column">
                            <ImageEditor
                                ref="imageEditor"
                                :auto-crop-area="1"
                                :image-src="'/map/header'"
                                :view-mode="1"
                                :container-width="800"
                                :image-width="bannerMinWidth"
                                :image-height="bannerMinHeight"
                                :resize-height="450"
                                :resize-width="2100"
                                :image-passes.sync="imagePasses"
                                drag-mode="move"
                                @image-changed="imageReady = true"
                            />
                        </div>
                        <div class="column guidelines">
                            <div class="left">
                                <h3>Suggested Photo Ideas:</h3>
                                <ul>
                                    <li
                                        v-for="g in suggestions"
                                        :key="g"
                                        class="field"
                                        v-html="g"
                                    ></li>
                                </ul>
                            </div>
                            <div class="right">
                                <h3>Required Photo Guidelines:</h3>
                                <ul>
                                    <li
                                        v-for="g in guidelines"
                                        :key="g"
                                        class="field"
                                        v-html="g"
                                    ></li>
                                </ul>
                            </div>
                        </div>
                        <div class="column">
                            <div class="has-text-centered">
                                <label
                                    v-if="imageReady && imagePasses"
                                    v-tooltip="{
                                        content: imageReady
                                            ? 'My image meets the requirements'
                                            : 'Click the arrow to upload an image',
                                        placement: 'bottom-start',
                                    }"
                                    class="checkbox"
                                    :class="{ disabled: !imageReady && !imagePasses }"
                                >
                                    <input
                                        v-model="gl_checked"
                                        :disabled="!imageReady"
                                        type="checkbox"
                                    />
                                    I verify this photo meets the guidelines.
                                </label>
                            </div>
                        </div>
                        <div class="column">
                            <p class="fineprint">
                                <strong>** *Please note:**</strong>
                                You will be notified by email within 48 hours if a submission is
                                denied.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="$_pendingImage()" slot="footer">
            <BaseBtn text="Pick a Different Image" @click.native="overwritePending = true" />
        </div>
        <div v-else slot="footer">
            <div v-if="submitted">
                <BaseBtn text="Close" @click.native="hideEditor()"></BaseBtn>
            </div>
            <div v-else class="footer">
                <BaseBtn
                    v-if="uploadBanner && !saving"
                    text="Go Back"
                    @click.native="uploadBanner = false"
                />
                <BaseBtn
                    v-if="uploadBanner && imageReady && gl_checked && !$refs.imageEditor.error"
                    :disabled="saving"
                    :text="saving ? 'Submiting...' : 'Submit for Approval'"
                    @click.native="$_save"
                />
                <BaseBtn
                    v-if="canSave()"
                    :disabled="saving"
                    :text="saving ? 'Saving...' : 'Save Banner'"
                    @click.native="save"
                />
            </div>
        </div>
    </ChangeModal>
</template>

<script>
import {
    find,
    isEqual,
    uniq,
    filter,
    flatten,
    map,
    cloneDeep,
    isEmpty,
    slice,
    every,
} from 'lodash';

import VueScrollTo from 'vue-scrollto';
import Multiselect from 'vue-multiselect';

import ChangeModal from './../ChangeModal.vue';
import ImageCard from './../widgets/ImageCard.vue';
import ImageEditor from './../ImageEditor.vue';

import { SaveImage } from './../mixins/SaveImage.js';

export default {
    components: {
        ChangeModal,
        ImageCard,
        Multiselect,
        ImageEditor,
    },
    mixins: [SaveImage],
    data() {
        return {
            aid: this.$store.getters.agent.aid,
            bannerMinWidth: 1400,
            bannerMinHeight: 350,
            search: [],
            saving: false,
            state: null,
            selected: '',
            expanded: '',
            images: [],
            displayedImages: [],
            doneLoadingImages: false,
            uploadBanner: null,
            error: null,
            submitted: false,
            imageReady: false,
            overwritePending: false,
            options: [
                { name: 'Default', code: 'de' },
                { name: 'Map', code: 'ma' },
            ],
            suggestedTags: [
                this.$store.getters.office.physicalAddress.province,
                // 'Winter',  // none tagged yet
                // 'Spring',  // none tagged yet
                'Summer',
                'Fall',
            ],
            loading: true,
            allImagesLoaded: false,
            loadingOverride: false,
            lastLoadLength: null,
            gl_checked: false,
            suggestions: [
                `The outside of your office can let viewers get a feel for where you're located.`,
                `The inside of your office, if you have a unique layout, furniture or features.`,
                `An approved car wrap image can be used as a banner photo.`,
                `Landscape imagery depicting the local geography or seasons`,
                `Agent and/or agent team members at a community or sponsored event`,
            ],
            guidelines: [
                `The photo is <strong>at least 1400px wide X 350px high</strong>.`,
                `This photo <strong>was not taken</strong> from another website or found on Google, Yahoo or Bing.`,
                `You own the rights to this photo`,
                `The only people in this photo (if any) are the Agent and agent team members`,
                `This is a photo, not an illustration.`,
                `The photo <strong>completely fills</strong> the rectangular area.`,
                `The photo does not have visible trademarked or copyrighted branding on clothing, artwork, background image, etc.`,
                `The photo doesn't use the Desjardins logo and modify it in any way.`,
                `The photo does not have text superimposed on it, including your name.`,
            ],
            keypath: 'office.banner_bg_url',
            imageType: 'banner',
            imagePasses: null,
        };
    },
    computed: {
        filteredImages() {
            return filter(cloneDeep(this.displayedImages), (image) => {
                return every(this.search, (stag) =>
                    find(image.tags, (tag) => tag.toLowerCase() == stag.name.toLowerCase()),
                );
            });
        },
    },
    watch: {
        search() {
            this.loadMore();
        },
    },
    mounted() {
        let bannerImages = this.$store.getters.editor.bannerImages;
        let tags = [];

        this.images.push({
            public_id: 'default',
            url: this.$store.getters.office.maps.image.header,
            tags: ['default', 'map'],
        });

        bannerImages.forEach((img) => {
            this.images.push(img);
        });

        this.doneLoadingImages = true;
        uniq(flatten(map(tags, (t) => t)))
            .sort()
            .forEach((tag) => this.newTag(tag));
        this.displayNextImageBatch();
    },
    methods: {
        isSelected(url) {
            return isEqual(url, this.selected);
        },
        isExpanded(url) {
            return isEqual(url, this.expanded);
        },
        save() {
            this.saving = true;
            let override = {
                value: this.selected,
                status: 'approved',
                key: 'office.banner_bg_url',
            };
            return this.$store
                .dispatch('SEND_CONTENT_OVERRIDE', override)
                .then(this.hideEditor)
                .then(() => {
                    this.saving = false;
                })
                .catch(() => {
                    // should do something with actual error
                    this.saving = false;
                    return this.$refs.modal.displayError(
                        new Error('problem creating the content override'),
                    );
                });
        },
        canSave() {
            return this.selected;
        },
        setSelectedImage(selectedImage) {
            if (selectedImage.url === this.selected) {
                this.selected = '';
            } else if (selectedImage) {
                this.selected = selectedImage.url;
            }
        },
        setExpandedImage(selectedImage, index) {
            if (selectedImage.url === this.expanded) {
                this.expanded = '';
            } else if (selectedImage) {
                this.expanded = '';
                this.expanded = selectedImage.url;
                setTimeout(() => {
                    VueScrollTo.scrollTo(this.$refs.card[index], 400, {
                        container: this.$refs.bannerlist,
                        easing: 'ease-in-out',
                        offset: -100,
                        y: true,
                    });
                }, 100);
            }
        },
        displayNextImageBatch() {
            this.loading = true;

            setTimeout(() => {
                let start = this.displayedImages.length;
                let newImages = slice(this.images, start, start + 16);

                this.displayedImages = this.displayedImages.concat(newImages);

                if (isEmpty(newImages)) {
                    this.allImagesLoaded = true;
                }
            }, 400);

            setTimeout(() => {
                this.loading = false;
            }, 1000);
        },
        getScrollContext() {
            return this.$refs.bannerlist;
        },
        newTag(newTag) {
            if (!this.checkTagExists(this.options, newTag)) {
                const tag = {
                    name: newTag.charAt(0).toUpperCase() + newTag.slice(1),
                    code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
                };
                this.options.push(tag);
            }
        },
        addTag(tag) {
            this.search.push(
                find(this.options, (opt) => opt.name.toLowerCase() == tag.toLowerCase()),
            );
        },
        toggleTag(tag) {
            if (this.checkTagExists(this.search, tag)) {
                this.search = this.search.filter(
                    (search) => search.name.toLowerCase() != tag.toLowerCase(),
                );
            } else if (this.checkTagExists(this.options, tag)) {
                this.search.push(
                    find(this.options, (opt) => opt.name.toLowerCase() == tag.toLowerCase()),
                );
            }
        },
        checkTagExists(values, tag) {
            return find(values, (val) => val.name.toLowerCase() == tag.toLowerCase());
        },
        isActive(tag) {
            let searchTags = this.search;
            if (searchTags != '') {
                return find(searchTags, (stag) => stag.name.toLowerCase() == tag.toLowerCase());
            }
        },
        loadMore() {
            this.loading = true;
            this.loadingOverride = true;
            this.displayNextImageBatch();
            if (!this.allImagesLoaded && this.filteredImages.length <= this.lastLoadLength + 16) {
                setTimeout(() => {
                    this.loadMore();
                }, 400);
            } else {
                this.loadingOverride = false;
            }
            this.lastLoadLength = this.filteredImages.length;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~vue-multiselect/dist/vue-multiselect.min.css';

.banner-header {
    display: flex;
    flex-direction: column;

    .image-search-text {
        padding: 5px 5px 20px 5px;
        flex-basis: 100%;
        border-right: 0px;
    }

    .or {
        flex-basis: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        margin: 5px 50px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:before,
        &:after {
            background-color: #000;
            content: '';
            display: inline-block;
            height: 3px;
            position: relative;
            vertical-align: middle;
            width: 50%;
        }
    }

    .custom-upload-header {
        padding: 20px 5px 5px 5px;
        flex-basis: 100%;
        cursor: pointer;
    }

    @include media('>md') {
        flex-direction: row;

        .image-search-text {
            padding: 5px 20px 5px 5px;
            flex-basis: 49%;
        }

        .or {
            min-width: 1px;
            max-width: 20px;
            flex-basis: 2%;
            margin: 5px;
            flex-direction: column;
            &:before,
            &:after {
                width: 3px;
                height: 100px;
            }
        }

        .custom-upload-header {
            padding: 5px 5px 5px 20px;
            flex-basis: 49%;
        }
    }
}
.banners-list {
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1%;
}
.col-sm-6,
.col-lg-4 {
    transition: width 100ms ease-in-out;
}
.load-more {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    width: 100%;
}

.columns {
    display: flex;
    width: 100%;
    flex-direction: column;

    .column {
        display: flex;
        width: 100%;
    }

    .guidelines {
        margin-bottom: 20px;
        div {
            margin: 10px;
        }
        li {
            height: auto;
        }
    }
}

.footer {
    display: flex;
}

input[type='checkbox'] {
    appearance: none;
    width: auto;
    margin-right: 10px;
    border: 1px solid gray;
    padding: 8px;

    &:checked {
        background-color: $blue;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='8 8 36 36' version='1.1' xml:space='preserve'%3E%3Cpath d='M14.1 27.2l7.1 7.2 16.7-16.8' fill='none' stroke='rgb%28255,255,255%29' stroke-width='5'/%3E%3C/svg%3E");
    }
}

.checkbox {
    color: $blue;
    margin-top: 20px;
    font-size: 20px;
}
</style>
