<template>
    <ChangeModal help="HelpPhoneNumberPlacement">
        <span slot="title">Primary Office Phone</span>
        <div slot="editor" ref="body">
            <div id="primary-number-location" class="section">
                <h3>Primary Number Location</h3>
                <p>
                    By default, we show your Primary Number just below your profile photo on the
                    left-hand side of the page. You can choose to move it to another location by
                    selecting one of the other options below. Note that this only affects the
                    location of the phone number on displays where the screen is wide enough to show
                    the profile on the left. On smaller screens, we rearrange your page to make it
                    more usable by your clients.
                </p>
                <div class="location-options">
                    <div
                        v-tooltip.bottom="'Place phone below profile photo'"
                        :class="{ selected: position === 'below-profile' }"
                        @click="position = 'below-profile'"
                    >
                        <h6>Below Profile Photo (default)</h6>
                        <img
                            alt="example of phone number below profile photo"
                            :class="{ selected: position == 'below-profile' }"
                            src="@/assets/img/phone-placement-below-profile.png"
                        />
                    </div>
                    <div
                        v-tooltip.bottom="'Place phone in black bar'"
                        :class="{ selected: position == 'black-bar' }"
                        @click="position = 'black-bar'"
                    >
                        <h6>In the Black Bar</h6>

                        <img
                            alt="example of phone number in the black bar"
                            :class="{ selected: position == 'black-bar' }"
                            src="@/assets/img/phone-placement-black-bar.png"
                        />
                    </div>
                    <div
                        v-tooltip.bottom="'Place phone in header'"
                        :class="{ selected: position == 'header' }"
                        @click="position = 'header'"
                    >
                        <h6>In the Header</h6>
                        <img
                            alt="example of phone number in the header"
                            :class="{ selected: position == 'header' }"
                            src="@/assets/img/phone-placement-header.png"
                        />
                    </div>
                </div>
            </div>

            <div class="profile-example">
                <div class="options">
                    <div id="primary-number" class="section">
                        <h3>Primary Number</h3>
                        <p>
                            By default, we use your "preferred" phone number from
                            <HelpSAP />
                            . If you would like us to use a different phone number as your Primary
                            Number, please select it below. Note that this will only affect your
                            Enhanced Microsite, your Default Microsite will still show the
                            "preferred" number from SAP/AMIT.
                        </p>
                        <div class="select">
                            <select v-model="preferred">
                                <option
                                    v-for="(phone, key) in phones"
                                    :key="key"
                                    :value="key"
                                    :label="`${startCase(key.replace('phone', 'Phone'))}: ${
                                        phone.number
                                    }`"
                                >
                                    {{ startCase(key.replace('phone', 'Phone')) }}:
                                    {{ phone.number }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div id="additional-numbers" class="section">
                        <h3>Additional Phone Numbers</h3>
                        <p>
                            You can select additional phone numbers to display below your profile
                            picture by enabling them below. Numbers with the name "Additional" will
                            be grouped with other "Phone" numbers
                        </p>

                        <label key="show-only-primary">
                            <input
                                v-model="additionalNumbersPreference"
                                name="addition-numbers"
                                value="onlyPrimary"
                                type="radio"
                            />
                            Only show primary number
                        </label>

                        <label key="match-default-microsite">
                            <input
                                v-model="additionalNumbersPreference"
                                name="addition-numbers"
                                value="matchDefaultMicrosite"
                                type="radio"
                            />
                            Match Default Microsite numbers.
                        </label>

                        <table v-if="additionalNumbersPreference === 'matchDefaultMicrosite'">
                            <tr v-for="(phone, key) in defaultMicrositeNumbers" :key="key">
                                <td class="type">
                                    <strong>{{ startCase(key.replace('phone', 'Phone')) }}:</strong>
                                </td>
                                <td class="number">
                                    {{ phone.number }}
                                </td>
                            </tr>
                        </table>

                        <label key="custom">
                            <input
                                v-model="additionalNumbersPreference"
                                name="addition-numbers"
                                value="custom"
                                type="radio"
                            />
                            Pick custom numbers to be displayed
                        </label>

                        <table v-if="additionalNumbersPreference === 'custom'">
                            <tr v-for="(phone, key) in customPhoneVisibility" :key="key">
                                <td class="switch">
                                    <switches
                                        v-model="phone.visible"
                                        :color="phone.visible ? 'blue' : 'unchecked'"
                                        class="item switch"
                                        theme="custom"
                                        :disabled="!phones[key]"
                                    />
                                </td>

                                <td class="type">
                                    <!-- yes we need this div for styling -->
                                    <div>
                                        <strong>
                                            {{ startCase(key.replace('phone', 'Phone')) }}:
                                        </strong>
                                    </div>
                                </td>
                                <td v-if="phones[key]" class="number">{{ phones[key].number }}</td>
                                <td v-else>INVALID OR MISSING NUMBER</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <Profile
                    :editor-view="true"
                    :phone-visibility.sync="phoneVisibility"
                    :override-preferred-phone.sync="profilePreferredPhone"
                />
            </div>
        </div>
        <div slot="footer">
            <BaseBtn
                :text="saving ? 'Saving...' : 'Save'"
                :disabled="!changed || saving"
                @click.native="save"
            ></BaseBtn>
        </div>
    </ChangeModal>
</template>

<script>
import { capitalize, get, isEqual, pickBy, startCase, cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';
import Switches from 'vue-switches';
import Profile from '@/components/SectionAbout/AgentProfile/Profile.vue';
import HelpSAP from '@/components/Editor/help/bits/HelpSAP.vue';
export default {
    name: 'PhoneNumberPlacement',
    components: {
        Profile,
        Switches,
        HelpSAP,
    },
    data() {
        return {
            saving: false,
            position: null,
            preferred: null,
            additionalNumbersPreference: null,
            customPhoneVisibility: null,
            phones: null,
            oldPhoneVisibility: null,
        };
    },
    computed: {
        ...mapGetters(['office']),
        defaultMicrositeNumbers() {
            return pickBy(this.phones, (phone) => phone.onMicrosite || phone.onDefaultMicrosite);
        },
        phoneVisibility() {
            if (this.additionalNumbersPreference === 'custom') {
                return Object.fromEntries(
                    Object.entries(this.phones).filter(([key, phone]) => {
                        return (
                            phone.number && (this.customPhoneVisibility?.[key]?.visible ?? false)
                        );
                    }),
                );
            } else if (this.additionalNumbersPreference === 'matchDefaultMicrosite') {
                return this.defaultMicrositeNumbers;
            } else {
                return {};
            }
        },
        changed() {
            return this.changedAdditional || this.changedPreferred || this.changedPlacement;
        },
        changedPreferred() {
            return this.preferred !== this.office.phones.preferred;
        },
        changedAdditional() {
            if (
                this.additionalNumbersPreference !== this.office.phones.additionalNumbersPreference
            ) {
                return true;
            }

            if (this.additionalNumbersPreference === 'custom') {
                return !isEqual(this.oldPhoneVisibility, this.customPhoneVisibility);
            }

            return false;
        },
        changedPlacement() {
            return this.position !== this.office.phones.position;
        },
        profilePreferredPhone() {
            const phone = get(this.office.phones, this.preferred, { number: '' });
            return {
                pretty: phone.number,
                stripped: phone.number.split(new RegExp('[-() ]', 'g')).join(''),
            };
        },
        officePhones() {
            return Object.fromEntries(
                Object.entries(this.office.phones).filter(
                    ([key]) =>
                        ![
                            'position',
                            'preferred',
                            'additionalNumbersPreference',
                            'phoneVisibility',
                        ].includes(key),
                ),
            );
        },
    },
    created() {
        const phones = this.office.phones;
        this.additionalNumbersPreference = phones.additionalNumbersPreference;
        this.preferred = phones.preferred;
        const filteredPhoneVisibility = {};

        //this prevents new overrides from copying visibility for phone numbers that are not valid anymore.
        //Only numbers that currently exist in office.phones will be used in new overrides
        Object.keys(phones.phoneVisibility || {}).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(this.officePhones, key)) {
                filteredPhoneVisibility[key] = phones.phoneVisibility[key];
            }
        });

        this.customPhoneVisibility = Object.keys(this.officePhones)?.reduce((acc, key) => {
            if (!acc[key]) {
                acc[key] = { visible: false };
            }
            return acc;
        }, filteredPhoneVisibility || {});

        this.position = phones.position;
        this.phones = this.officePhones;

        // becaues of how we're showing a preview of the additional phone visibility,
        // we need to store the current custom phone visibility here
        this.oldPhoneVisibility = cloneDeep(this.office.phones.phoneVisibility);
    },

    methods: {
        capitalize,
        startCase,
        save() {
            this.saving = true;

            let overrides = [];
            if (this.changedPreferred) {
                overrides.push(this.savePreferred());
            }
            if (this.changedPlacement) {
                overrides.push(this.savePlacement());
            }
            if (this.changedAdditional) {
                overrides.push(this.saveAdditional());
            }

            Promise.all(overrides)
                .then(this.hideEditor)
                .finally(() => (this.saving = false));
        },
        savePreferred() {
            const override = {
                value: this.preferred,
                status: 'approved',
                key: 'office.phones.preferred',
            };
            return this.$store.dispatch('SEND_CONTENT_OVERRIDE', override).catch(() => {
                return this.$refs.modal.displayError(
                    new Error('problem creating the content override for Primary Number'),
                );
            });
        },
        savePlacement() {
            const override = {
                value: this.position,
                status: 'approved',
                key: 'office.phones.position',
            };
            return this.$store.dispatch('SEND_CONTENT_OVERRIDE', override).catch(() => {
                return this.$refs.modal.displayError(
                    new Error('problem creating the content override for Primary Number Placement'),
                );
            });
        },
        saveAdditional() {
            let overrides = [];

            overrides.push(
                this.$store.dispatch('SEND_CONTENT_OVERRIDE', {
                    value: this.additionalNumbersPreference,
                    status: 'approved',
                    key: 'office.phones.additionalNumbersPreference',
                }),
            );

            if (this.additionalNumbersPreference === 'custom') {
                overrides.push(
                    this.$store.dispatch('SEND_CONTENT_OVERRIDE', {
                        value: this.customPhoneVisibility,
                        status: 'approved',
                        key: 'office.phones.phoneVisibility',
                    }),
                );
            }

            return Promise.all(overrides).catch(() => {
                return this.$refs.modal.displayError(
                    new Error('problem creating the content override for Additional Numbers'),
                );
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.section {
    margin-bottom: 40px;
}

.profile-example {
    display: flex;
    justify-content: space-between;

    .options {
        display: flex;
        flex-direction: column;
        padding-right: 40px;
    }

    .profile {
        max-width: 300px;
    }
}

#primary-number-location {
    .location-options {
        display: flex;
        justify-content: space-around;

        div {
            display: flex;
            flex-direction: column;
            padding: 10px;

            &:hover {
                cursor: pointer;
            }

            &.selected {
                background-color: $light-blue;
            }

            h6 {
                align-self: center;
                display: inline-block;
                color: darken($blue, 25%);

                @include media('<=md') {
                    margin: 20px 15px 0 0;
                }
            }
        }
    }
}

#primary-number {
    .select {
        margin-top: 20px;
        border: 2px solid $gray;
        width: auto;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 20px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 5px 0 5px;
            border-color: $blue transparent transparent transparent;
            margin-top: -5px;
            pointer-events: none;
        }
    }
}

#additional-numbers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    label {
        align-items: center;
        display: flex;
        font-size: 16px;
        margin-top: 5px;
    }

    input[type='radio'] {
        appearance: none;
        width: 20px;
        height: 20px;
        margin: 0 5px 0 0;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid $blue;
        transition: background 0.4s, border-color 0.4s, box-shadow 0.4s;

        &:checked {
            background: $blue;
            box-shadow: inset 0 0 0 2px #fff;
        }
    }

    table {
        font-size: 16px;
        margin-left: 40px;
        margin-bottom: 0px;

        tr {
            td {
                padding: 2px 0px;
                align-items: center;

                &:nth-child(1) {
                    width: 1px;
                }

                &.type {
                    width: 1px;
                    padding-right: 10px;

                    div {
                        width: max-content;
                    }
                }

                &.switch {
                    padding-right: 10px;
                }
            }
        }
    }
}
</style>
