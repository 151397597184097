var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"change-modal"}},[_c('div',{attrs:{"id":"change-modal-wrapper"},on:{"mousedown":_vm.mouseDown,"mouseup":_vm.mouseUp}},[_c('div',{staticClass:"change-modal-container",attrs:{"id":"change-modal-container"},on:{"mousedown":_vm.mouseDown,"mouseup":_vm.mouseUp}},[_c('div',{staticClass:"change-modal-header"},[_c('div',{staticClass:"title"},[_c('span',[_vm._t("title-prefix",function(){return [_vm._v(" "+_vm._s(_vm.showingHelp ? 'Help for' : 'Editing')+" ")]})],2),_c('span',{staticClass:"title-slot"},[_vm._t("title",function(){return [_vm._v("title")]})],2)]),(_vm.showCancel)?_c('BaseBtn',{staticClass:"closer",attrs:{"mobileshowicon":true,"icon":{
                        color: 'black',
                        height: '20',
                        name: 'close',
                        width: '20',
                    }},nativeOn:{"click":function($event){return _vm.hideEditor()}}}):_vm._e()],1),(!_vm.showingHelp)?_c('div',{staticClass:"description"},[_vm._t("description")],2):_vm._e(),_c('transition-group',{staticClass:"notification-list",attrs:{"name":"change-slide-fade","tag":"div"}},_vm._l((_vm.notificationsToDisplay),function(notification,index){return _c('div',{key:notification.message + index,staticClass:"notification",class:[notification.type]},[_c('span',{staticClass:"message"},[_vm._v(_vm._s(notification.message))]),_c('BaseBtn',{staticClass:"dismiss",attrs:{"text":"dismiss"},nativeOn:{"click":function($event){return _vm.closeNotification(index)}}})],1)}),0),_c('div',{staticClass:"change-modal-body"},[(_vm.help && _vm.showingHelp)?_c('div',{staticClass:"helpbody"},[_c(_vm.$root.$options.components[_vm.help],{tag:"component",attrs:{"helpcontext":_vm.helpcontext}})],1):_vm._t("editor")],2),_c('div',{staticClass:"change-modal-footer is-clearfix"},[_c('div',{staticClass:"is-pulled-left mode-buttons"},[(_vm.help && !_vm.showingHelp)?_c('HelpBtn'):_vm._e(),(_vm.$slots.editor && _vm.showingHelp)?_c('BaseBtn',{staticClass:"modeBtn edit",attrs:{"text":"Edit","icon":{
                            color: 'white',
                            height: '15',
                            name: 'pencil',
                            width: '15',
                        }},nativeOn:{"click":function($event){return _vm.toggleHelp()}}}):_vm._e()],1),_c('div',{staticClass:"is-pulled-right action-buttons"},[(!_vm.showingHelp)?_vm._t("footer"):_vm._e(),(_vm.showCancel)?_c('BaseBtn',{staticClass:"cancel",attrs:{"text":"Cancel"},nativeOn:{"click":function($event){return _vm.hideEditor()}}}):_vm._e()],2)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }