<template>
    <div :style="{ width: containerWidth + 'px' }" class="image-editor-wrapper">
        <div
            v-show="imageChanged"
            :style="{ height: containerHeight + 'px', width: containerWidth + 'px' }"
            :class="{ 'circle-mask': circleMask }"
            class="image-wrapper"
        >
            <img id="image" :src="image" alt="edited image" />
        </div>
        <div
            v-if="!imageChanged"
            :style="{ height: containerHeight + 'px', width: containerWidth + 'px' }"
            class="upload"
        >
            <svgicon
                class="cloud-icon"
                icon="upload-cloud"
                width="150"
                height="150"
                :original="true"
                @click.native="uploadImage()"
            />
        </div>
        <div v-if="error && imageChanged" class="notification is-warning">
            <strong>Warning!</strong>
            &nbsp;
            <span v-html="error"></span>
            &nbsp;
            <HelpBtn :force="true" :style="{ fontSize: '12px' }" />
        </div>
        <div v-show="imageChanged" class="controls">
            <div class="buttons">
                <div v-if="rotateButtons || zoomButtons" class="button-group">
                    <!--                         <button
                            :class="buttonClass"
                            v-tooltip.bottom="'Rotate Left'"
                            @click="rotateLeft"
                        >
                            <EMIcon icon="rotate-left"/>
                        </button> -->
                    <button
                        v-if="rotateButtons"
                        v-tooltip.bottom="'Rotate Right'"
                        :class="buttonClass"
                        @click="rotateRight"
                    >
                        Rotate 90&deg;
                        <!-- <EMIcon icon="rotate-right"/> -->
                    </button>

                    <button
                        v-if="zoomButtons"
                        v-tooltip.bottom="'Zoom In'"
                        :class="buttonClass"
                        @click="zoomIn"
                    >
                        Zoom In
                        <!-- <EMIcon icon="search-plus"/> -->
                    </button>
                    <button
                        v-if="zoomButtons"
                        v-tooltip.bottom="'Zoom Out'"
                        :class="buttonClass"
                        @click="zoomOut"
                    >
                        Zoom Out
                        <!-- <EMIcon icon="search-minus"/> -->
                    </button>
                </div>

                <div v-if="uploadButton || resetButton" class="button-group">
                    <label
                        v-if="uploadButton"
                        v-tooltip.bottom="'Click to upload a different photo'"
                        :class="buttonClass"
                    >
                        <input id="imageUpload" type="file" @change="fileChange" />
                        Select Image
                        <!-- <EMIcon icon="cloud-upload"/> -->
                    </label>

                    <button
                        v-if="resetButton"
                        v-tooltip.bottom="'Click to reset your edits'"
                        :class="buttonClass"
                        @click="resetImage"
                    >
                        Reset Adjustments
                        <!-- <EMIcon icon="times-circle-o"/> -->
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
    name: 'ImageEditor',
    props: {
        // Cropperjs options
        viewMode: { type: Number, default: 0 },
        dragMode: { type: String, default: 'crop' },
        autoCropArea: { type: Number, default: 0.8 },

        // ImageEditor options
        circleMask: { type: Boolean, default: false },
        uploadButton: { type: Boolean, default: true },
        resetButton: { type: Boolean, default: true },
        rotateButtons: { type: Boolean, default: true },
        zoomButtons: { type: Boolean, default: true },
        buttonClass: { type: String, default: 'image-editor-button' },
        sliderColor: { type: String, default: null },
        imageSrc: { type: String, default: null },
        containerHeight: { type: Number, default: 350 },
        containerWidth: { type: Number, default: 350 },
        imageWidth: { type: Number, default: 200 },
        imageHeight: { type: Number, default: 200 },
        resizeHeight: { type: Number, default: null },
        resizeWidth: { type: Number, default: null },
    },
    data() {
        return {
            cropper: {},
            image: this.imageSrc,
            sliderValue: 0,
            imageChanged: false,
            cropperOptions: {},
            error: null,
            cropHeight: null,
            cropWidth: null,
            imageTested: true,
        };
    },
    computed: {
        imageData() {
            return this.cropper.getImageData();
        },
        imageIsHighEnoughQuality() {
            let { naturalWidth, naturalHeight } = this.cropper.getImageData();
            return naturalHeight >= this.imageHeight && naturalWidth >= this.imageWidth;
        },
        errors() {
            return {
                selectedTooSmall: `The selected image area is too small &mdash; <strong>zoom out</strong> or <strong>increase</strong> the size of the selected area.`,
                imageSizeWrong: `Your image is <strong>${this.imageData.naturalWidth}</strong>px wide X <strong>${this.imageData.naturalHeight}</strong>px tall
                &mdash; It needs to be at least <strong>${this.imageWidth}</strong>px wide X <strong>${this.imageHeight}</strong>px tall.
                Please select a larger image.`,
            };
        },
    },
    mounted() {
        let image = document.getElementById('image');
        let vm = this;
        this.cropperOptions = {
            aspectRatio: this.imageWidth / this.imageHeight,
            viewMode: this.viewMode,
            dragMode: this.dragMode,
            minContainerHeight: this.containerHeight,
            minContainerWidth: this.containerWidth,
            autoCropArea: this.autoCropArea,
            crop: function (e) {
                vm.cropHeight = e.detail.height;
                vm.cropWidth = e.detail.width;
                if (!vm.imageTested) {
                    vm.testImage();
                } else if (e.detail.width < vm.imageWidth || e.detail.height < vm.imageHeight) {
                    vm.$emit('update:imagePasses', false);
                    if (vm.imageIsHighEnoughQuality) {
                        vm.error = vm.errors.selectedTooSmall;
                    } else {
                        vm.error = vm.errors.imageSizeWrong;
                    }
                } else {
                    vm.error = null;
                    vm.$emit('update:imagePasses', true);
                }
            },
        };
        this.cropper = new Cropper(image, this.cropperOptions);
    },
    methods: {
        getCanvasAsBlob() {
            //
            return new Promise((resolve) => {
                this.cropper
                    .getCroppedCanvas({
                        height: this.resizeHeight,
                        width: this.resizeWidth,
                    })
                    .toBlob(
                        (blob) => {
                            resolve(blob);
                        },
                        'image/jpeg',
                        1,
                    );
            });
        },
        uploadImage() {
            document.getElementById('imageUpload').click();
        },
        fileChange(e) {
            if (window.FileReader) {
                let file = e.target.files;
                let reader = new FileReader();
                reader.readAsDataURL(file[0]);

                reader.onload = (evt) => {
                    let img = new Image();
                    img.src = evt.target.result;

                    this.imageTested = false;
                    this.cropper.replace(img.src);
                    this.imageChanged = true;
                    this.$emit('image-changed');
                };
                reader.onerror = console.error;
            } else {
                alert('FileReader is not supported in your browser.');
            }
        },
        testImage() {
            this.imageTested = true;
            this.error = null;
            if (this.imageIsHighEnoughQuality /*natural image >= to min height/width*/) {
                let { width, height } = this.cropper.getData();
                if (width < this.imageWidth || height < this.imageHeight) {
                    this.zoomOut();
                    this.testImage();
                } else {
                    this.$emit('update:imagePasses', true);
                }
            } else {
                this.error = this.errors.imageSizeWrong;
            }
        },
        resetImage() {
            this.cropper.reset();
        },
        rotateLeft() {
            this.cropper.rotate(-90);
        },
        rotateRight() {
            this.cropper.rotate(90);
        },
        zoomIn() {
            this.cropper.zoom(0.1);
        },
        zoomOut() {
            this.cropper.zoom(-0.1);
        },
    },
};
</script>

<style lang="scss">
.cloud-icon {
    z-index: 10;
    cursor: pointer;
    text-align: center;
    margin: auto;
    width: 50%;
}

.image-editor-button {
    border-radius: 4px;
    cursor: pointer; /* Pointer/hand icon */
    font-size: 12px;
    white-space: nowrap;
    font-weight: normal;
    color: #fff;
    padding: 2px 5px;
    text-decoration: none;
    align-items: center;
    border: none;
    text-align: center;

    background-color: $blue;

    &:hover {
        // color:#fff;
        background-color: $light-blue;
    }

    i.c1-icon {
        font-size: 14px;
        margin-left: 3px;
        @include media('<=sm') {
            display: none;
        }
    }

    @include media('<=md') {
        font-size: 10px;
    }
}

.image-editor-wrapper {
    img {
        max-width: 100%;
    }

    .upload {
        align-items: center;
        box-shadow: 0 0 0px 11px rgba(0, 0, 0, 0.5) inset;
        display: flex;
        background-image: linear-gradient(#e6e7e8 1px, transparent 1px),
            linear-gradient(90deg, #e6e7e8 1px, transparent 1px);
        background-size: 20px 20px, 20px 20px;
        background-position: -2px -2px, -2px -2px;

        i {
            color: $blue;
            cursor: pointer;
            font-size: 80px;
            margin: auto;
        }
    }

    .circle-mask {
        .cropper-view-box,
        .cropper-face {
            border-radius: 0%;
        }
    }

    .notification {
        font-size: 14px;
        margin: 5px 0;
        padding: 8px;
    }

    .controls {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 5px 0 20px 0;

        input[type='file'] {
            cursor: pointer;
            display: none;
        }

        .buttons {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            button,
            label {
                margin: 0 2px;
            }

            .button-group {
                display: flex;
                margin: 5px;
            }
        }
    }
}
</style>
