<template>
    <HelpBaseBlock :editnow="true" editaction="GoogleReview">
        <p>
            The quality and quantity of reviews on Google is one of the most important ranking
            factors for local SEO (Search Engine Optimization). When a customer scans the search
            results for a local product or service on Google, the business listings that include
            customer reviews present greater credibility and, naturally, receive more clicks.
            Source: Rocket Digital, May 15, 2020
        </p>
        <p>
            To help your agency generate more reviews, you can display a Google icon and "Review us
            on Google" on your EM site.
        </p>
        <p>
            Once "Review us on Google" is displaying on your EM site, you can also share this review
            link on Social Media and other digital properties:
            <a :href="meta.baseuri + '/reviews'">{{ `${meta.baseuri}/reviews` }}</a>
        </p>
        <p slot="source">
            Google provides a unique identifier for your office, called the 'Place ID'. We keep this
            id on file, and use it to create a link to your Google Reviews page.
        </p>
    </HelpBaseBlock>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters(['meta']),
    },
};
</script>
