import HelpAR from './HelpAR.vue';
import HelpARAgent from './HelpARAgent.vue';
import HelpARTeam from './HelpARTeam.vue';
import HelpBtn from './HelpBtn.vue';
import HelpSAP from './HelpSAP.vue';
import HelpExampleEMDomain from './HelpExampleEMDomain.vue';
import HelpComingSoon from './HelpComingSoon.vue';
import HelpImageSize from './HelpImageSize.vue';
import HelpMicrosite from './HelpMicrosite.vue';
import HelpSupport from './HelpSupport.vue';
import HelpNoStuff from './HelpNoStuff.vue';
import HelpPleaseNote from './HelpPleaseNote.vue';
import HelpEMGuidelines from './HelpEMGuidelines.vue';
import HelpTerm from './HelpTerm.vue';

export default {
    HelpAR,
    HelpARAgent,
    HelpARTeam,
    HelpBtn,
    HelpComingSoon,
    HelpSAP,
    HelpExampleEMDomain,
    HelpImageSize,
    HelpMicrosite,
    HelpSupport,
    HelpNoStuff,
    HelpPleaseNote,
    HelpEMGuidelines,
    HelpTerm,
};
