import HelpBaseBlock from './HelpBaseBlock.vue';

import HelpAboutMe from './HelpAboutMe.vue';
import HelpProfileImage from './HelpProfileImage.vue';
import HelpPhoneNumberPlacement from './HelpPhoneNumberPlacement.vue';
import HelpBannerImage from './HelpBannerImage.vue';
import HelpContactMe from './HelpContactMe.vue';
import HelpDesignations from './HelpDesignations.vue';
import HelpLandmark from './HelpLandmark.vue';
import HelpJobs from './HelpJobs.vue';
import HelpLanguages from './HelpLanguages.vue';
import HelpMaps from './HelpMaps.vue';
import HelpMission from './HelpMission.vue';
import HelpName from './HelpName.vue';
import HelpOfficeHours from './HelpOfficeHours.vue';
import HelpPhones from './HelpPhones.vue';
import HelpProducts from './HelpProducts.vue';
import HelpArticles from './HelpArticles.vue';
import HelpSocial from './HelpSocial.vue';
import HelpTeam from './HelpTeam.vue';
import HelpGoogleReview from './HelpGoogleReview.vue';

export default {
    HelpAboutMe,
    HelpArticles,
    HelpBaseBlock,
    HelpProfileImage,
    HelpPhoneNumberPlacement,
    HelpBannerImage,
    HelpDesignations,
    HelpContactMe,
    HelpJobs,
    HelpLanguages,
    HelpMaps,
    HelpMission,
    HelpName,
    HelpOfficeHours,
    HelpProducts,
    HelpPhones,
    HelpSocial,
    HelpTeam,
    HelpLandmark,
    HelpGoogleReview,
};
