<template>
    <div>
        <EditBar></EditBar>

        <!-- <keep-alive> -->
        <component :is="activeEditor()"></component>
        <!-- </keep-alive> -->
    </div>
</template>

<script>
/* eslint-env browser */
import { polyfills } from '../../utils/polyfills.js';
import axios from 'axios';

import EditBar from './EditBar.vue';
import AboutMe from './types/AboutMe.vue'; // used
import AgentProfileImage from './types/AgentProfileImage.vue'; //used
import ContactMe from './types/ContactMe.vue'; // used
import Designations from './types/Designations.vue';
import Mission from './types/Mission.vue'; // used
import Jobs from './types/Jobs.vue'; // used
import Team from './types/Team.vue'; // used
import Landmark from './types/Landmark.vue'; // used
import TeamMember from './types/TeamMember.vue';
import BannerImage from './types/BannerImage.vue'; // used
import OfficeHours from './types/OfficeHours.vue'; // used
import MapDirections from './types/MapDirections.vue'; // used
import PageArticles from './types/PageArticles.vue';
import Social from './types/Social.vue'; // used
import Name from './types/Name.vue'; // used
import Phones from './types/Phones.vue';
import PhoneNumberPlacement from './types/PhoneNumberPlacement.vue'; // used
import Products from './types/Products.vue'; // used
import Languages from './types/Languages.vue';
import SessionExpired from './SessionExpired.vue';
import GoogleReview from './types/GoogleReview.vue';

const editorViews = {
    AboutMe,
    AgentProfileImage,
    PageArticles,
    BannerImage,
    PhoneNumberPlacement,
    ContactMe,
    Designations,
    Jobs,
    Mission,
    OfficeHours,
    Team,
    TeamMember,
    MapDirections,
    Social,
    Name,
    Phones,
    Products,
    Languages,
    SessionExpired,
    Landmark,
    GoogleReview,
};

export default {
    components: { EditBar },
    data() {
        return {};
    },
    mounted() {
        polyfills();
        const self = this;
        // capture escape key
        window.addEventListener('keyup', function (e) {
            if (e.code === 'Escape' && self.activeEditor()) {
                self.hideEditor();
            }
        });
        this.fetchBannerImages();
    },
    methods: {
        activeEditor() {
            const name = this.$store.getters.editor.editing;
            const v = editorViews[name];
            if (name && !v) {
                console.error('no editorView registered for', name);
            }
            return editorViews[this.$store.getters.editor.editing] || null;
        },
        fetchBannerImages() {
            axios
                .get(`${this.$store.getters.apiHost}/backend/api/v1/amit/bannerimages`, {
                    withCredentials: true,
                })
                .then((resp) => {
                    this.$store.commit('UPDATE_EDITOR', { bannerImages: resp.data });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
};

import Vue from 'vue';

// import general use editor/help components
import helpers from './help/AllHelpers';
Object.keys(helpers).forEach((key) => {
    Vue.component(key, helpers[key]);
});

import ChangeModal from './ChangeModal.vue';
Vue.component('ChangeModal', ChangeModal);
</script>

<style lang="scss"></style>
