import axios from 'axios';
import _ from 'lodash';
// const URL = require('url');

export const SaveImage = {
    data() {
        return {
            saving: false,
            ovrride: false,
        };
    },
    methods: {
        $_save() {
            this.saving = true;
            if ((!this.error && this.gl_checked) || this.override) {
                this.$_getBlob().then((blob) => {
                    let apiUrl = this.$store.getters.apiUrl;
                    let s3authurl = new URL(
                        `${apiUrl}/s3authurl/${this.$store.getters.meta.domain}/`,
                    );
                    s3authurl.searchParams.append('aid', this.aid);
                    s3authurl.searchParams.append('ext', blob.type.replace('image/', ''));
                    s3authurl.searchParams.append('type', this.imageType);
                    s3authurl.searchParams.append('contentType', blob.type);
                    s3authurl.searchParams.append('cacheControl', 'max-age=31536000');

                    return axios({
                        method: 'GET',
                        url: s3authurl,
                        xsrfHeaderName: 'X-CSRFToken',
                        xsrfCookieName: 'csrftoken',
                        withCredentials: true,
                    })
                        .then((resp) => {
                            // create form to send to s3. order of appends matters!
                            let formData = new FormData();
                            _.forIn(resp.data.fields, (value, key) => formData.append(key, value));
                            formData.append('acl', 'public-read');
                            formData.append('Content-Type', blob.type);
                            formData.append('Cache-Control', 'max-age=31536000');
                            formData.append('file', blob);

                            return axios({
                                method: 'POST',
                                url: resp.data.url,
                                data: formData,
                                config: { headers: { 'Content-Type': blob.type } },
                            }).then(() => resp.data.url + '/' + resp.data.fields.key);
                        })
                        .then((fileUrl) => {
                            return this.$store.dispatch('SEND_CONTENT_OVERRIDE', {
                                value: fileUrl,
                                status: 'pending',
                                key: this.keypath,
                                subkey: this.subkeypath,
                            });
                        })
                        .then(() => {
                            this.submitted = true;
                            this.saving = false;
                        })
                        .then((r) => new Promise((resolve) => setTimeout(() => resolve(r), 3000)))
                        .catch((err) => {
                            this.saving = false;
                            console.error(err);
                            // should do something with actual error
                            // return this.$refs.modal.displayError(
                            // new Error('problem creating the content override')
                            // );
                        });
                });
            } else {
                this.saving = false;
                return this.$refs.modal.displayError(
                    new Error(
                        'failed to validate all requirements. Please check the required boxes on the left',
                    ),
                );
            }
        },
        $_getBlob() {
            if (!this.$refs.imageEditor) {
                return false;
            }

            return this.$refs.imageEditor
                .getCanvasAsBlob()
                .then((blob) => {
                    if (_.isError(blob)) {
                        this.$refs.modal.displayError(blob);
                        return false;
                    }
                    return blob;
                })
                .catch(console.error);
        },
        $_revertToDefault() {
            return this.$store
                .dispatch('DELETE_CONTENT_OVERRIDE', {
                    type: this.contentType,
                    key: this.keypath,
                })
                .then(() => {
                    let commitData = {};
                    commitData[this.keypath] = this.$store.state.core.agent.image.large;
                })
                .then(() => {
                    this.hideEditor();
                })
                .catch((err) => {
                    console.error(err);
                    this.$refs.modal.displayError(new Error('problem reverting to default'));
                });
        },
        $_notDefault() {
            return _.has(this.$store.getters.overrides, `approved.${this.keypath}`);
        },
        $_pendingImage() {
            if (this.overwritePending || this.submitted) {
                return false;
            }
            const pendingCO = _.find(this.$store.getters.pendingOverrides, (co) => {
                return co.key === this.keypath && co.subkey === this.subkeypath;
            });
            return _.get(pendingCO, 'value', null);
        },
    },
};
