import { render, staticRenderFns } from "./HelpTeam.vue?vue&type=template&id=795b3ec4&scoped=true"
var script = {}
import style0 from "./HelpTeam.vue?vue&type=style&index=0&id=795b3ec4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "795b3ec4",
  null
  
)

export default component.exports