// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%278 8 36 36%27 xml:space=%27preserve%27%3E%3Cpath d=%27m14.1 27.2 7.1 7.2 16.7-16.8%27 fill=%27none%27 stroke=%27%23FFF%27 stroke-width=%275%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pending-image[data-v-6df9eb40]{margin:auto;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.pending-image img[data-v-6df9eb40]{margin:10px;-ms-flex-item-align:center;align-self:center;max-width:250px}.body-content[data-v-6df9eb40],.pending-footer[data-v-6df9eb40]{display:-webkit-box;display:-ms-flexbox;display:flex}.body-content[data-v-6df9eb40]{margin:auto}.body-content .instructions[data-v-6df9eb40]{margin-left:10px}.body-content .instructions ul[data-v-6df9eb40]{padding-left:30px}.body-content .instructions ul li[data-v-6df9eb40]{font-size:14px;margin-left:3px;padding:0;height:auto}.body-content .instructions input[type=checkbox][data-v-6df9eb40]{-webkit-appearance:none;-moz-appearance:none;appearance:none;width:auto;margin-right:10px;border:1px solid gray;padding:8px}.body-content .instructions input[type=checkbox][data-v-6df9eb40]:checked{background-color:#739dd2;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}.body-content .instructions .checkbox[data-v-6df9eb40]{color:#739dd2;margin-top:20px;font-size:20px}.body-content .instructions .disabled[data-v-6df9eb40]{cursor:not-allowed!important;opacity:.5}.body-content .instructions .fineprint[data-v-6df9eb40]{font-size:14px;margin-top:10px;color:#24292d}.submitted-text[data-v-6df9eb40]{font-size:24px}.submitted-text p[data-v-6df9eb40]{padding-bottom:15px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
