<template>
    <ChangeModal ref="modal">
        <span slot="title">{{ member.names.NickName }}</span>
        <span slot="description">Update the information for this team member.</span>
        <span slot="editor">
            <span v-if="uploadProfilePic">
                <ImageUploader
                    :aid="`${member.aid}`"
                    :aspect-ratio="100 / 126"
                    :circle-mask="true"
                    :guidelines="guidelines"
                    :keypath="`team.members`"
                    :subkeypath="`${member.aid}.image.profile`"
                    help="HelpProfileImage"
                    image-type="team member"
                    title="Team Member Profile Photo"
                />
            </span>
            <span v-else>
                <div class="profile-photo">
                    <div class="member-avatar">
                        <div>
                            <img
                                v-if="member.image.profile"
                                :src="member.image.profile"
                                alt="team member photo"
                            />
                            <span v-else v-html="member.initials"></span>
                        </div>
                    </div>
                    <BaseBtn
                        class="upload-btn"
                        text="Upload new profile photo"
                        @click.native="uploadProfilePic = true"
                    ></BaseBtn>
                    <HelpPleaseNote>
                        This is a partial list of the most common guidelines &mdash; a photo can be
                        rejected for any violations of the
                        <a
                            :href="`${publicPath}docs/Agency_Marketing_Information_Tool_-_Photo_FAQ_4-11-2018.docx`"
                            target="_blank"
                            aria-describedby="new-window-warning"
                            rel="noopener noreferrer"
                        >
                            Photo Compliance Guidelines
                        </a>
                        . We'll review the photo and send the results by e-mail within 2 business
                        days.
                        <br />
                        <br />
                        Photos uploaded here, if approved,
                        <strong>will not</strong>
                        change the
                        <HelpSAP />
                        or affect
                        <HelpMicrosite />
                        .
                    </HelpPleaseNote>
                </div>
                <EditTextArea
                    :text.sync="description"
                    :results.sync="compliance"
                    placeholder="About Me..."
                />
            </span>
        </span>
        <span slot="footer">
            <BaseBtn
                v-tooltip="$_compliancyError(!descriptionCompliant())"
                :text="saveButtonText"
                :disabled="saving || unchanged() || !descriptionCompliant()"
                @click.native="saveAll"
            />
        </span>
    </ChangeModal>
</template>

<script>
import EditTextArea from './../EditTextArea.vue';
import ImageUploader from './../widgets/ImageUploader.vue';
import { cloneDeep, get as _get } from 'lodash';
import { Tooltips } from './../mixins/Tooltips.js';

export default {
    label: 'Team Member',
    components: { EditTextArea, ImageUploader },
    mixins: [Tooltips],
    data() {
        const name = this.$store.getters.editor.context.names.NickName;

        return {
            compliance: null,
            description: cloneDeep(_get(this.$store.getters.editor.context, 'description', '')),
            member: this.$store.getters.editor.context,
            saving: false,
            publicPath: process.env.BASE_URL,
            uploadProfilePic: false,
            guidelines: [
                `The photo is <strong>at least 200px wide X 200px high</strong>.`,
                `The photo is of the upper-body, <strong>not</strong> full length.`,
                `This is a reasonably current photo of ${name}.`,
                `The <strong>entire face</strong> is visible (no sunglasses).`,
                `This photo is of <strong>${name} alone</strong>.`,
                `This is a photo, not an illustration.`,
                `This photo presents a <strong>professional image</strong>.`,
                `${name} is wearing business or business casual attire.`,
                `The photo <strong>completely fills</strong> the circular area.`,
            ],
        };
    },
    computed: {
        saveButtonText() {
            if (this.compliance && this.compliance.status === 'pending') {
                return 'Checking...';
            } else if (this.saving) {
                return 'Saving';
            } else {
                return 'Save Changes';
            }
        },
    },
    methods: {
        descriptionChanged() {
            return this.description === '' || this.description !== this.member.description;
        },
        descriptionCompliant() {
            return (
                this.compliance &&
                this.compliance.status === 'passed' &&
                this.compliance.value === this.description
            );
        },
        unchanged() {
            return !this.descriptionChanged();
        },
        save(value, key, subkey) {
            return this.$store.dispatch('SEND_CONTENT_OVERRIDE', {
                value,
                status: 'approved',
                key,
                subkey,
            });
        },
        saveAll() {
            this.saving = true;
            let promises = [];

            if (this.descriptionChanged()) {
                promises.push(
                    this.save(this.description, `team.members`, `${this.member.aid}.description`),
                );
            }

            Promise.all(promises)
                .catch(() => {
                    this.$refs.modal.displayError(
                        new Error('There was a problem saving your changes! Please try later.'),
                    );
                })
                .finally(() => {
                    this.saving = false;
                    this.hideEditor();
                });

            /* const edits = _.get(this.$store.getters.ctx, path, {});
             * edits[this.member.associate_id] = _.extend(edits[this.member.associate_id] || {}, {
             *     staff_copy: this.staff_copy,
             * });

             * return this.$store
             *            .dispatch('SEND_CONTENT_OVERRIDE', {
             *                type: 'team_member_staff_copy',
             *                key: path,
             *                value: edits,
             *                status: 'approved',
             *            })
             *            .then(this.hideEditor)
             *            .then(r => {
             *                this.saving = false;
             *            })
             *            .catch(err => {
             *                this.saving = false;
             *                return this.$refs.modal.displayError(
             *                    new Error('problem creating the content override')
             *                );
             *            });*/
        },
    },
};
</script>

<style lang="scss" scoped>
.profile-photo {
    display: flex;
    margin-bottom: 15px;

    .upload-btn {
        padding: 3px 10px;
        font-weight: normal;
        height: 30px;
    }

    .member-avatar {
        width: 70px;
        padding: 0;
        margin: 0;
        margin-right: 20px;

        div {
            text-align: center;
            overflow: hidden;

            img {
                width: 100%;
            }

            span {
                font-size: 35px;
                line-height: 1.8;
                color: #fff;
            }
        }
    }

    @include media('<=md') {
        flex-direction: column;
        justify-content: center;

        .upload-btn {
            margin: 10px;
            padding: 3px 10px;
            font-weight: normal;
            height: 30px;
        }

        .member-avatar {
            width: 100%;

            div {
                img {
                    width: 70px;
                }
            }
        }
    }
}
</style>
