<template>
    <span class="time-picker">
        <input
            class="display-time"
            :class="{ error: error }"
            :readonly="!disabled"
            :disabled="disabled"
            :value="displayTime"
            type="text"
            @click.stop="toggleDropdown"
        />
        <div v-if="showDropdown" class="time-picker-overlay" @click.stop="toggleDropdown"></div>
        <div v-show="showDropdown" class="dropdown">
            <div class="select-list">
                <ul class="tp-hours">
                    <li class="hint">hour</li>
                    <li
                        v-for="hr in hours"
                        :key="hr"
                        :class="{ active: formatTwelveHour === hr }"
                        @click.stop="onHourSelect(hr)"
                    >
                        {{ formatValue(hr) }}
                    </li>
                </ul>
                <ul class="minutes">
                    <li class="hint">minute</li>
                    <li
                        v-for="m in minutes"
                        :key="m"
                        :class="{ active: currentMinutes === m }"
                        @click.stop="onMinuteSelect(m)"
                    >
                        {{ formatValue(m) }}
                    </li>
                </ul>
                <ul class="apms">
                    <li class="hint">apm</li>
                    <li
                        v-for="a in ['AM', 'PM']"
                        :key="a"
                        :class="{ active: apm === a }"
                        @click.stop="onApmSelect(a)"
                    >
                        {{ a }}
                    </li>
                </ul>
            </div>
        </div>
    </span>
</template>

<script>
import { addHours, setHours, setMinutes, format } from 'date-fns/esm';

export default {
    props: {
        value: {
            type: [Date],
            default: () => ({}),
        },
        format: {
            type: String,
            default: 'hour:minutes a',
        },
        disabled: {
            type: Boolean,
        },
        error: {
            type: Boolean,
        },
    },
    data() {
        return {
            showDropdown: false,
            muteWatch: false,
        };
    },
    computed: {
        displayTime() {
            return format(this.value, 'h:mm a');
        },
        hours() {
            let hours = [];
            for (let i = 1; i <= 12; i++) {
                hours.push(i);
            }
            return hours;
        },
        minutes() {
            let minutes = [];
            for (let i = 0; i < 60; i += 15) {
                minutes.push(i);
            }
            return minutes;
        },
        apm() {
            return format(this.value, 'a');
        },
        currentMinutes() {
            return Number(format(this.value, 'mm'));
        },
        formatTwelveHour() {
            return Number(format(this.value, 'h'));
        },
    },
    methods: {
        formatValue(i) {
            return String(i);
            /* return i < 10 ? `0${i}` : String(i);*/
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown && !this.disabled;
        },
        computeHour(value) {
            if (this.apm === 'AM') {
                return value === 12 ? 0 : value;
            }
            return value === 12 ? 12 : value + 12;
        },
        onHourSelect(newHour) {
            if (newHour === this.formatTwelveHour) return;
            let newDate = setHours(this.value, this.computeHour(newHour));
            this.$emit('input', newDate);
        },
        onMinuteSelect(newMinutes) {
            if (newMinutes === this.currentMinutes) return;
            let newDate = setMinutes(this.value, newMinutes);
            this.$emit('input', newDate);
        },
        onApmSelect(newApm) {
            if (newApm === this.apm) return;
            let newDate = addHours(this.value, newApm === 'PM' ? 12 : -12);
            this.$emit('input', newDate);
        },
        isEmpty() {
            for (const key in this.value) {
                if (this.value[key] === null) {
                    return true;
                }
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.time-picker {
    display: inline-block;
    position: relative;
    font-size: 1em;
    width: 7em;
    font-family: $base-font;
    vertical-align: middle;

    * {
        box-sizing: border-box;
    }

    input.display-time {
        border: 1px solid $dark-gray;

        &.error {
            border: 1px solid $red;
        }

        &.empty {
            border: 1px solid $red;
        }

        width: 7em;
        height: 2.2em;
        padding: 0.3em 0.5em;
        font-size: 1em;
        text-align: center;
    }

    .clear-btn {
        position: absolute;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        top: 0;
        right: 0;
        bottom: 0;
        margin-top: -0.15em;
        z-index: 3;
        font-size: 1.1em;
        line-height: 1em;
        vertical-align: middle;
        width: 1.3em;
        color: #d2d2d2;
        background: rgba(255, 255, 255, 0);
        text-align: center;
        font-style: normal;

        -webkit-transition: color 0.2s;
        transition: color 0.2s;
    }

    .clear-btn:hover {
        color: $gray;
        cursor: pointer;
    }

    .time-picker-overlay {
        z-index: 2;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .dropdown {
        position: absolute;
        border-radius: 2px;
        z-index: 5;
        top: calc(2.2em + 2px);
        left: 0;
        background: white;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
        width: 11em;
        height: 10em;
        font-weight: normal;

        .select-list {
            width: 11em;
            height: 10em;
            overflow: hidden;
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
            justify-content: space-between;

            ul {
                padding: 0;
                margin: 0;
                height: 10em;
                list-style: none;
                flex: 1 0 0%;
                overflow-x: hidden;
                overflow-y: auto;

                &.minutes,
                &.apms {
                    border-left: 1px solid white;
                }

                li {
                    text-align: center;
                    padding: 0.3em 0;
                    color: black;
                    margin: 1px 5px;

                    &:not(.hint):hover {
                        background: rgba(0, 0, 0, 0.08);
                        color: #161616;
                        cursor: pointer;
                    }

                    &.active,
                    &.active:hover {
                        background: $blue;
                        color: white;
                    }
                }
            }

            .hint {
                color: #a5a5a5;
                cursor: default;
                font-size: 0.8em;
            }
        }
    }
}
</style>
