<template>
    <HelpBaseBlock :editnow="true">
        <p>
            Your banner image is a way to give some visual appeal to your Enhanced Microsite and
            personalize your office based on your location, team or other unique aspects.
        </p>
        <p>Choose from one of the many pre-approved photos available or upload your own.</p>

        <HelpPleaseNote>
            If the image doesn't meet
            <strong>both</strong>
            the minimum height and width, the image cannot be used.
        </HelpPleaseNote>

        <div slot="source">
            <p>
                By default, we'll display a custom map image here with a pin marking your office's
                location.
            </p>
        </div>

        <div slot="troubleshooting">
            <HelpImageSize :helpcontext="helpcontext"></HelpImageSize>
        </div>
    </HelpBaseBlock>
</template>
<script>
export default {
    props: {
        helpcontext: { type: Object, default: null },
        // editnow    : { type: [String,Boolean], default: false },
        // editaction : { type: String, default: null },
        // source     : { type: String, default: null },
        // subject    : { type: String, default: null },
    },
};
</script>
