<template>
    <HelpBaseBlock :editnow="hasSocial ? true : 'partial'">
        <p>
            Displaying Social Media badges and associated Social Media "feeds" are a good way to
            show people additional avenues that they can connect with you or learn more about your
            office and events.
        </p>
        <p>
            The Facebook "feed" will be enabled by default if there's a valid Facebook URL in the
            SAP and activated in the
            <HelpSAP />
            .
        </p>
        <div slot="troubleshooting">
            <div v-if="hasSocial">
                <p>
                    <HelpTerm>Facebook Feed Not Displaying:</HelpTerm>
                    If you have Facebook set up in your SAP profile and a Facebook link is
                    displaying on
                    <HelpMicrosite />
                    but it's not working on your Enhanced Microsite then it's most likely a
                    permissions issue with your Facebook profile.
                </p>
                <p>Here are the steps to modify your Facebook permissions:</p>
                <ol>
                    <li>Go to your Facebook page</li>
                    <li>Click Settings at the top of your page</li>
                    <li>Under General, Click Edit to the right of Country Restrictions</li>
                    <li>
                        Remove any countries from the textbox and select the option "Hide this Page
                        from viewers in these countries" if it's not already selected.
                    </li>
                    <li>Click Save Changes</li>
                    <li>Under General, Click Edit to the right of Age restrictions</li>
                    <li>Select Anyone (13+)</li>
                    <li>Click Save Changes</li>
                </ol>
                <br />
                <p>
                    The image below shows how your permissions should look when configured
                    correctly.
                </p>

                <img
                    src="@/assets/img/facebook-privacy-settings.png"
                    alt="facebook privacy settings"
                />
                <p>
                    Once you've updated your Facebook permissions, your Facebook feed will begin to
                    show up on your Enhanced Microsite (EM) in around 10 minutes. Make sure to
                    reload your EM page to see the changes.
                </p>
            </div>

            <div v-else>
                <HelpTerm>No Social Media Available</HelpTerm>
                You currently don't have any social media visible in your
                <HelpSAP />
                , which means it won't show up on your microsite. To add social media, you have to
                add it to the
                <HelpSAP />
                . Refer to the SAP Social Media instructions on EAP to learn more about using social
                media.
            </div>
        </div>

        <div slot="editing">
            <p>
                Displaying Social Media badges and feeds requires you to have them added and
                activated in your
                <HelpSAP />
                .
            </p>
        </div>
    </HelpBaseBlock>
</template>

<script>
export default {
    props: {
        type: { type: String, default: null },
        editIcon: { type: String, default: 'pencil' },
        editText: { type: String, default: 'Edit' },
        helpIcon: { type: String, default: 'question-circle-o' },
        helpText: { type: String, default: 'Help' },
        hasHelp: { type: Boolean, default: true },
        hasEditor: { type: Boolean, default: false },
        context: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    computed: {
        hasSocial() {
            return !!this.$store.getters.office.social;
        },
    },
    methods: {},
};
</script>
